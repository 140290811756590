import styled from 'styled-components'
import SocialBlock from './Social/Social'
import { useTranslation } from 'react-i18next'

import eggLogo from '../assets/egg_no_shadow.svg'

export const Footer = () => {
    const { t } = useTranslation('home')

    const hideFooter = window.location.host.includes('formaciondixital.egg.live')
    if (hideFooter) return null

    return (
        <>
            <span>
                <a
                    href="https://egg.live/terms-and-conditions/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ fontWeight: 'normal' }}
                >
                    {t('Terms and Conditions')}
                </a>
            </span>
            <FooterRightColum>
                <a
                    href="https://egg.live"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="egg-live"
                >
                    <img src={eggLogo} alt="Egg Live" />
                    {t('Egg Live')}
                </a>
                <SocialBlock />
            </FooterRightColum>
        </>
    )
}

const FooterRightColum = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 3em;
    .egg-live {
        display: flex;
        column-gap: 0.3em;
        font-weight: normal;
        img {
            width: 12px;
        }
    }
`
