import { Outlet, useParams } from 'react-router-dom'
import { ResponsiveLayout } from './ResponsiveLayout'
import UserContextProvider from '../context/UserContext'
import styled from 'styled-components'
import { defaultTheme } from '../styles/themes/defaultTheme'
import { CourseHeader } from '../pages/course/CourseHeader'
import { ContentNavigation } from '../components/Content/ContentNavigation'
import { useResponsive } from '../hooks/useResponsive'
import { MyTeam } from '../components/MyTeam/MyTeam'
import { MobileFooterBar } from '../components/NavBar/MobileFooterBar'
import { useState } from 'react'

export const CourseLayout = () => {
    const { slugOrId } = useParams()

    return (
        <UserContextProvider>
            <ResponsiveLayout
                header={null}
                content={
                    <Container>
                        <CourseHeader slugOrId={slugOrId} />
                        <ContentNavigation />
                        <Outlet />
                    </Container>
                }
                aside={<Footer />}
                footer={null}
                allowScroll={false}
            />
        </UserContextProvider>
    )
}

const Footer = () => {
    const [showWidget, setShowWidget] = useState(false)
    const { isDesktop } = useResponsive()
    return isDesktop ? <MyTeam /> : <MobileFooterBar showWidget={showWidget} setShowWidget={setShowWidget} />
}

const Container = styled.section`
    padding: 0 3rem 6rem 3rem;
    display: flex;
    flex-direction: column;
    background-color: ${defaultTheme.color.offWhite};

    @media screen and (max-width: 768px) {
        padding: 0 1rem 6rem 1rem;
    }
`
