import LoadingPage from './static/LoadingPage'
import { getUrlParameters, updateSessionParams } from '../helpers/urls'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUser } from '../hooks/queries/useUser'
import { useToken } from '../hooks/useToken'

export const Redirect = () => {
    const navigate = useNavigate()
    const { _id } = useToken()
    const { goTo, isLoading } = useUser()
    const { redirect, ...params } = getUrlParameters()

    // User redirection
    useEffect(() => {
        if (!_id) return navigate('/login')
        if (redirect) {
            const to = redirect
            updateSessionParams({ ...params, redirect: undefined })
            if (to === '/') return navigate('/home')
            else return navigate(`${redirect}`)
        }
        if (goTo) return navigate(goTo)
        navigate('/home')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return isLoading ? <LoadingPage /> : null
}
