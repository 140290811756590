import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

export const Migration = () => {
    const navigate = useNavigate()
    const { t } = useTranslation('signUp')

    return (
        <Styles>
            <section style={{ textAlign: 'center' }}>
                <div>
                    <FontAwesomeIcon
                        icon={['fat', 'circle-check']}
                        className="container-icon ppal-icon"
                        style={{ height: 80, color: '#00A3FF' }}
                    />
                </div>
                <br />
                <h2>{t(`We're almost there!`)}</h2>
                <br />
                <p>
                    {t(
                        'We update our platform and also the information we request to enter. Next we are going to ask you for some additional information to complete your profile.'
                    )}
                </p>
                <br />

                <br />
                <div className="buttons column">
                    <button className="primary" onClick={() => navigate('/registro')}>
                        {t('Continue')}
                    </button>
                    <br />
                    <button className="text" onClick={() => navigate(-1)}>
                        {t('Cancel')}
                    </button>
                </div>
            </section>
        </Styles>
    )
}

const Styles = styled.div`
    text-align: center;
`
