import { useQuery } from '@tanstack/react-query'
import { getParsedStudyPlan } from '../infrastructure/api-widget'
import { useUserContentProgression } from './useUserContentProgression'
import { unLockPlan } from '../helpers/studyPlan'

export const useCourseStudyPlan = (courseId?: string) => {
    const {
        userSession: { sessionId },
    } = useUserContentProgression(courseId)
    const queryKey = ['Study Plan', courseId]

    const { data, ...rest } = useQuery({
        queryKey,
        queryFn: () => getParsedStudyPlan(courseId!),
        enabled: !!courseId,
    })

    const userDaySession = sessionId
    const todaySessionIndex = data?.sessions?.findIndex(({ id }) => id === sessionId)
    const todaySession = todaySessionIndex ? data?.sessions[todaySessionIndex] : undefined
    const plan = unLockPlan({ plan: data, userDaySession })

    const firstSession = plan?.sessions[0]
    const firstStepId = firstSession?.steps[0]?.id

    const findSessionById = (sessionId?: string) => plan?.sessions.find(({ id }) => id === sessionId)
    const findSessionByStepId = (stepId?: string) => plan?.sessions.find(session => session.steps.some(({ id }) => id === stepId))
    const findStepById = (stepId?: string) =>
        plan?.sessions.map(session => session.steps.find(({ id }) => id === stepId)).find(step => step)

    return {
        plan,
        firstSession,
        firstStepId,
        todaySession,
        findSessionByStepId,
        findSessionById,
        findStepById,
        queryKey,
        ...rest,
    }
}
