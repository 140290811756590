import { StaticInfoBlock } from '../../components/Content/StaticInfoBlock'
import Met2 from '../../assets/static_pages/met_2.png'
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'
import ModalPageLayout from '../../layouts/ModalPageLayout'

const MethodologyPage = () => {
    return (
        <ModalPageLayout>
            <div style={{ maxWidth: '700px', margin: '0 auto' }}>
                <h1 style={{ marginBottom: '-30px' }}>Te damos la bienvenida</h1>
                <StaticInfoBlock>
                    <div className="block-content">
                        <div className="info-container">
                            <LiteYouTubeEmbed
                                id="qhfVFDD0fBQ"
                                title="Te damos la bienvenida 2023 | Egg"
                                playerClass="lty-playbtn"
                                wrapperClass="yt-lite"
                            />
                            <h4 style={{ marginTop: '50px' }}>Metodología de aprendizajes</h4>
                            <LiteYouTubeEmbed
                                id="Mh2jaU_VyIw"
                                title="Nuestra metodología | Egg"
                                playerClass="lty-playbtn"
                                wrapperClass="yt-lite"
                            />
                            <h4 style={{ marginTop: '50px' }}>Aprendizaje en Red + Pulsos</h4>
                            <LiteYouTubeEmbed
                                id="0BgknoTDSbg"
                                title="Nuestra metodología | Egg"
                                playerClass="lty-playbtn"
                                wrapperClass="yt-lite"
                            />
                            <LiteYouTubeEmbed
                                id="beYoGWOU_bk"
                                title="Nuestra metodología | Egg"
                                playerClass="lty-playbtn"
                                wrapperClass="yt-lite"
                            />
                        </div>
                    </div>
                </StaticInfoBlock>
                <StaticInfoBlock>
                    <div className="block-content">
                        <div className="info-container">
                            <h4>Rotaciones</h4>
                            <img src={Met2} alt="Block illustration" />
                            <p>
                                En cada encuentro en vivo te encontrarás con un equipo diferente. La
                                interacción con tus pares te ayudará a desarrollar habilidades
                                esenciales ultra demandadas en el mercado laboral: resolución de
                                problemas, construcción colectiva, comunicación y liderazgo.
                            </p>
                        </div>
                    </div>
                </StaticInfoBlock>
                <StaticInfoBlock>
                    <div className="block-content">
                        <div className="info-container">
                            <h4>Evolución del estudiante</h4>
                            <LiteYouTubeEmbed
                                id="jfJfMSVEBa8"
                                title="Nuestra metodología | Egg"
                                playerClass="lty-playbtn"
                                wrapperClass="yt-lite"
                            />
                        </div>
                    </div>
                </StaticInfoBlock>
                <StaticInfoBlock>
                    <div className="block-content">
                        <div className="info-container">
                            <h4>Staff Egg</h4>
                            <LiteYouTubeEmbed
                                id="ytNGcNXP_7I"
                                title="Nuestra metodología | Egg"
                                playerClass="lty-playbtn"
                                wrapperClass="yt-lite"
                            />
                        </div>
                    </div>
                </StaticInfoBlock>

                <StaticInfoBlock>
                    <div className="block-content">
                        <div className="info-container">
                            <h4>Código de conducta</h4>
                            <LiteYouTubeEmbed
                                id="EFpjkPsSi88"
                                title="Nuestra metodología | Egg"
                                playerClass="lty-playbtn"
                                wrapperClass="yt-lite"
                            />
                        </div>
                    </div>
                </StaticInfoBlock>
            </div>
        </ModalPageLayout>
    )
}

export default MethodologyPage
