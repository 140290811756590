import { useNavigate } from 'react-router-dom'
import { clearSession } from '../../../infrastructure/api-signup'
import { useEffect } from 'react'
import LoadingPage from '../../static/LoadingPage'

export const CloseSession = () => {
    const navigate = useNavigate()

    useEffect(() => {
        clearSession().finally(() => navigate('/login'))
    }, [navigate])

    return <LoadingPage />
}

export default CloseSession
