import styled, { css } from 'styled-components'
import { useOrganization } from '../../../hooks/useOrganization'
import { LogoOrganization } from '../../../components/LogoOrganization'

export const HeaderOrganization = () => {
    const { branding } = useOrganization()

    if (!branding) return null

    if (!branding.headerImage || !branding.logo) return null

    return (
        <Section className="car rounded border-style" bgImage={branding.headerImage}>
            <LogoOrganization />
        </Section>
    )
}

const Section = styled.section<{ bgImage: string }>(({ bgImage }) => {
    return css`
        width: 100%;
        height: 10rem;
        margin-top: 2rem;
        background: url(${bgImage}) center / cover no-repeat;
        display: flex;
        align-items: center;
    `
})
