import React, { FocusEventHandler, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ContinueWithGoogle } from '../../../components/ContinueWithGoogle'
import { CheckBox } from '../../../components/FormElements/Checkbox'
import { Input, preventSpaceInput } from '../../../components/FormElements/Input'
import { PasswordConfirmation } from '../../../components/FormElements/PasswordConfirmation'
import { isEmailValid } from '../../../helpers/validators'
import { useCreateUser } from '../../../hooks/queries/useCreateUser'
import { useUser } from '../../../hooks/queries/useUser'
import { PostCreateUser } from '../api-register'
import { Separator } from '../../../components/Separator'
import { defaultTheme } from '../../../styles/themes/defaultTheme'
import { getUrlParameters } from '../../../helpers/urls'

export const SignUpStep1 = () => {
    const { t } = useTranslation('signUp')
    const { user } = useUser()
    const { create, migrate, isPending: isLoading, isError } = useCreateUser()
    const queryParams = getUrlParameters()
    const queryEmail = new URLSearchParams(window.location.search).get('email')

    const [changes, setChanges] = useState<Partial<PostCreateUser>>({
        email: '',
        termsAndConditions: false,
        password: '',
        ...queryParams,
    })
    const isMigration = !!user?.requireMigration

    const [error, setError] = useState('')
    const [validPasswords, setValidPasswords] = useState(false)

    const { email, password, termsAndConditions } = changes

    const onChange: FocusEventHandler<HTMLInputElement> = ({
        target: {
            name,
            value,
            validationMessage,
            validity: { valid },
        },
    }) => {
        if (name === 'email') {
            !valid ? setError(validationMessage) : setError('')
        }
        setChanges(prev => ({ ...prev, [name]: value }))
    }
    const isCompleted =
        !!email &&
        isEmailValid(email) &&
        !!password &&
        validPasswords &&
        !!changes.termsAndConditions

    const onSubmit = (event?: React.FormEvent<HTMLFormElement>) => {
        event?.preventDefault()
        if (!isCompleted) return
        isMigration ? migrate(changes as PostCreateUser) : create(changes as PostCreateUser)
    }
    useEffect(() => {
        if (!user?.email) return
        setChanges(prev => ({ ...prev, email: user.email ?? '' }))
    }, [user?.email])

    useEffect(() => {
        if (!queryEmail) return
        setChanges(prev => ({ ...prev, email: queryEmail ?? '' }))
    }, [queryEmail])

    if (isLoading) return <Skeleton />

    return (
        <SignUpStyle>
            <h1 className="title">{t(isMigration ? 'Update account' : 'Create account')}</h1>
            <ContinueWithGoogle />
            <form onSubmit={onSubmit}>
                <Input
                    {...{
                        label: t('Email'),
                        id: 'email',
                        name: 'email',
                        type: 'email',
                        placeholder: 'username@mail.com',
                        autoComplete: 'email',
                        value: email,
                        disabled: isMigration,
                        onChange,
                        error,
                        required: true,
                        autoFocus: !email,
                        onKeyDown: preventSpaceInput,
                    }}
                />
                <br />
                <PasswordConfirmation
                    {...{
                        password: password ?? '',
                        changes,
                        setChanges,
                        setValidPasswords,
                        autoFocus: !!email,
                    }}
                />
                <br />
                <div className="terms_conditions">
                    <CheckBox
                        name="termsAndConditions"
                        id="termsAndConditions"
                        style={{
                            width: '1.5em',
                        }}
                        checked={termsAndConditions}
                        onChange={({ target: { checked } }) => {
                            setChanges({ ...changes, termsAndConditions: checked })
                        }}
                        required={!termsAndConditions}
                    />
                    <span>
                        <Trans
                            t={t}
                            i18nKey={'I accept the Privacy Policy and Terms and Conditions. I want to be part of this community!.'}
                        >
                            I accept the{' '}
                            <a href="https://egg.live/privacy-policy" target="_blank" rel="noopener noreferrer">
                                Privacy Policy
                            </a>
                            and{' '}
                            <a href="https://egg.live/terms-and-conditions" target="_blank" rel="noopener noreferrer">
                                Terms and Conditions
                            </a>
                        </Trans>
                    </span>
                </div>
                {isError && (
                    <p style={{ color: '#ff647c', textAlign: 'center' }}>
                        {t('It is not possible to continue with the registration at the moment, please try again later.')}
                    </p>
                )}
                <br />
                <button className="primary" children={t('Continue')} style={{ width: '100%' }} disabled={!validPasswords} />
            </form>
        </SignUpStyle>
    )
}

const Skeleton = () => {
    return (
        <SignUpStyle>
            <div className="skeleton title" />
            <br />
            <div className="skeleton button" />
            <Separator />
            <form>
                <Input className="skeleton input" />
                <br />
                <Input className="skeleton input" />
                <br />
                <Input className="skeleton input" />
                <br />
                <div className="skeleton input" />
                <br />
                <div className="skeleton button" />
            </form>
        </SignUpStyle>
    )
}

export const SignUpStyle = styled.div`
    & > .user-email {
        margin: 1rem auto;
        font-weight: 700;
        p {
            margin: 0.3rem 0;
            font-size: 0.86rem;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 18px;
        }
        .email {
            margin-bottom: 1rem;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 0.5em;
            word-break: break-all;
            & .logout-container {
                display: flex;
                align-items: center;
                & > * {
                    color: ${defaultTheme.color.electricBlue};
                }
                & .logout-button {
                    width: auto;
                    min-height: max-content;
                    margin: 0;
                    font-size: 0.87rem;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    .terms_conditions {
        display: flex;
        gap: 10px;
        font-size: 12px;
    }
    & button {
        width: 100%;
        width: -webkit-fill-available;
        margin: 0 auto;
        @media screen and (max-width: 768px) {
            width: 100% !important;
        }
    }
    .skeleton.title {
        width: 100%;
        height: 24px;
    }
    .skeleton.text {
        height: 48px;
    }

    .skeleton.input {
        width: 100%;
        height: 73px;
    }
    .skeleton.button {
        width: 100%;
        height: 56px;
    }
`