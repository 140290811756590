import { Dispatch, SetStateAction } from 'react'
import { Icon } from '../Icon'
import { MyTeam } from '../MyTeam/MyTeam'
import { defaultTheme } from '../../styles/themes/defaultTheme'
import styled from 'styled-components'
import { useUserCourses } from '../../hooks/queries/useUserCourses'
import { useNavigate } from 'react-router-dom'
import { useActiveCourse } from '../../pages/course/hooks/useActiveCourse'
import { useCourseStudyPlan } from '../../hooks/useCourseStudyPlan'

export const MobileFooterBar = ({ showWidget, setShowWidget }: { showWidget: boolean; setShowWidget: Dispatch<SetStateAction<boolean>> }) => {
    const navigate = useNavigate()
    const { currentCourse } = useUserCourses()
    const { todaySession: todaySection } = useCourseStudyPlan(currentCourse?.courseId)
    const { activeCourse } = useActiveCourse()
    const alreadyAssigned = currentCourse?.alreadyAssigned

    const currentStep = todaySection?.steps[0]?.id

    const onClick = () => {
        if (activeCourse?.hasExternalContent && currentStep) {
            navigate(`/content/step/${currentStep}`)
        }
    }
    return (
        <ContentFooterStyle>
            {showWidget && (
                <div className="widget">
                    <MyTeam />
                </div>
            )}
            <div
                style={{
                    display: 'flex',
                    height: '100%',
                    justifyContent: 'flex-start',
                    padding: '.3em 2em',
                }}
            >
                <button
                    className="icon"
                    onClick={() => {
                        onClick?.()
                        setShowWidget(false)
                    }}
                >
                    <Icon icon={['fas', 'folder']} className="button-icon" />
                </button>

                <button className="icon" onClick={() => setShowWidget(!showWidget)} disabled={!alreadyAssigned}>
                    <Icon icon={['fas', 'bolt']} />
                </button>
            </div>
        </ContentFooterStyle>
    )
}

const ContentFooterStyle = styled.nav`
    width: 100%;
    height: 100%;
    border-top: 1px solid ${defaultTheme.color.lightGray};

    & > .buttons {
        height: 100%;
        display: grid !important;
        grid-template-columns: auto auto max-content !important;
        justify-content: space-evenly !important;
        align-items: center;
        grid-gap: 0.2rem;
        & > button {
            margin-left: 0 !important;
            min-width: 85px !important;
        }
    }

    & > .widget {
        position: absolute;
        top: 0;
        left: 0;
        height: calc(100dvh - ${defaultTheme.mobileHeight.footer});
        width: 100%;
        z-index: 3;
        overflow: hidden;
        ::-webkit-scrollbar {
            width: 0px;
        }
        scroll-behavior: smooth;
        & > section {
            width: 100%;
            min-height: 100%;
            height: 100%;
            z-index: 1;
            padding: 1.5em;
            border-radius: 0;
            box-shadow: none;
        }
    }
`
