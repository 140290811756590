import { AddCalendarButton } from '../../components/Buttons/AddCalendarButton'
import { MeetButton } from '../../components/CourseCard/MeetButton'
import { CourseProgression } from '../../components/Content/CourseProgression'
import { useTranslation } from 'react-i18next'
import { useUserCourse } from '../../hooks/queries/useUserCourses'
import styled from 'styled-components'
import { defaultTheme } from '../../styles/themes/defaultTheme'

export const CourseHeader = ({ slugOrId }: { slugOrId?: string }) => {
    const { t } = useTranslation('dashboard')
    const { course, status, courseId } = useUserCourse(slugOrId)

    if (status === 'pending') return <CourseHeaderSkeleton />

    return (
        <Header className="content-header-container">
            <Card className="card">
                <div className="left">
                    <h1 className="title no-margins">{course?.courseName}</h1>
                    <CourseProgression courseId={courseId} />
                </div>

                {course?.asyncCourse ? (
                    <div />
                ) : (
                    <div className="display-flex card-buttons">
                        <AddCalendarButton
                            buttonLabel={t('Add to calendar')}
                            className="text custom-button only-desktop"
                            iconCalendar={['fas', 'calendar-circle-plus']}
                        />
                        <div>{course?.status === 'finished' ? t('Your course has ended.') : ''}</div>
                        {course && <MeetButton {...course} isInside={true} />}
                    </div>
                )}
            </Card>
        </Header>
    )
}

const CourseHeaderSkeleton = () => {
    return <header className="card skeleton"></header>
}

const Header = styled.header`
    background-color: ${defaultTheme.color.offWhite};

    & > .card {
        align-items: center;
        justify-content: space-between;
    }

    @media screen and (max-width: 1300px) {
        & .card-buttons {
            flex-direction: column-reverse;
            gap: 0;
        }
    }

    @media screen and (max-width: 470px) {
        & .card {
            flex-direction: column;
            gap: 1em;
            align-items: center;
            text-align: center;
        }
    }
`

const Card = styled.article`
    display: flex;
    justify-content: space-between;
`
