import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useUIContext } from '../../context/UIContext'
import { useToken } from '../../hooks/useToken'
import { AWS } from '../../infrastructure/api-aws'
import { useUpdateUserImage } from '../../hooks/queries/useUpdateUserImage'
import { useUser } from '../../hooks/queries/useUser'

const AvatarEgg = (props: AvatarEggProps) => {
    const { showError } = useUIContext()
    const { t } = useTranslation('profile')
    const { src, title, isEditable = false, size, isExternal } = props
    const fileInput = useRef<HTMLInputElement>(null)
    const { user, isLoading } = useUser()
    const { uploadImageAsync, isImageLoading } = useUpdateUserImage()
    const { token } = useToken()
    const [userImage, setUserImage] = useState('')
    const [isAWSUpdating, setIsAWSUpdating] = useState(false)
    const { urlImage } = user || {}

    const genericError = t('Error uploading image, try again later.')
    const maxImageSizeMB = 4.3
    const availableImageTypes = ['image/jpeg', 'image/png', 'image/jpg']

    const handleFileSelect = async ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
        const fileToUpload = files && files[0]

        if (!fileToUpload) return

        if (fileToUpload.size / 1024 / 1024 > maxImageSizeMB) {
            showError(
                t('The image is too large. The maximum size is {{size}}MB.', {
                    size: maxImageSizeMB,
                })
            )
            return
        }

        if (!availableImageTypes.includes(fileToUpload.type)) {
            showError(t('The image must be in JPG, JPEG or PNG format.'))
            return
        }
        setIsAWSUpdating(true)

        const formData = new FormData()
        formData.append('file', fileToUpload)

        handleUploadPhoto(formData)
    }

    const handleUploadPhoto = async (formData: FormData) => {
        if (!user || !token) return
        try {
            const urlImage = await AWS.uploadImage({
                formData,
                userId: user.userId,
                token,
            })
            if (!urlImage) return showError(genericError)

            await uploadImageAsync(urlImage)
        } catch (_error) {
            showError(genericError)
        } finally {
            formData.delete('file')
            setIsAWSUpdating(false)
        }
    }

    useEffect(() => {
        setUserImage(isExternal ? src || '' : urlImage || '')
    }, [urlImage, src, user, isExternal])

    return (
        <AvatarEggStyled {...{ src: userImage, size }}>
            <input
                style={{ display: 'none' }}
                type="file"
                onChange={event => handleFileSelect(event)}
                ref={fileInput}
                accept={availableImageTypes.join(',')}
            />
            <div className="main">
                {isLoading ? (
                    <FontAwesomeIcon className="btn-icon" icon={['far', 'spinner']} spin />
                ) : (isExternal && !src) || !userImage ? (
                    <span className="gravatar">
                        {title
                            ?.split(' ')
                            .map((word: string) => word.slice(0, 1))
                            .join('')
                            .toUpperCase()
                            .substring(0, 2)}
                    </span>
                ) : (
                    <img src={src ?? userImage} alt={title} className={isAWSUpdating || isImageLoading ? 'opacity' : ''} />
                )}
                {isEditable &&
                    (!isAWSUpdating && !isImageLoading ? (
                        <button className="overlay" onClick={() => fileInput.current?.click()} aria-label="Upload profile image">
                            <FontAwesomeIcon className="btn-icon" icon={['far', 'pen-to-square']} />
                        </button>
                    ) : (
                        <div className="overlay permanent">
                            <FontAwesomeIcon className="btn-icon" icon={['far', 'spinner']} spin />
                        </div>
                    ))}
            </div>
        </AvatarEggStyled>
    )
}

export default AvatarEgg

interface AvatarEggProps {
    src?: string
    title?: string
    size?: { mobile: number; desktop: number }
    style?: Record<string, any>
    isEditable?: boolean
    disabled?: boolean
    isExternal?: boolean
}

export const AvatarEggStyled = styled.div<{
    src?: string
    size: AvatarEggProps['size']
}>`
    width: fit-content;
    .main {
        background: inherit;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        border-radius: 100%;
        width: ${({ size }) => (size ? `${size.desktop}px` : '110px')};
        height: ${({ size }) => (size ? `${size.desktop}px` : '110px')};
        @media (max-width: 768px) {
            border-radius: 0;
            width: ${({ size }) => (size ? `${size.mobile}px` : '100px')};
            height: ${({ size }) => (size ? `${size.mobile}px` : '100px')};
        }

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.8;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            text-align: center;
            font-size: 12px;
            border: none;
            margin: 0;
            font-size: 0px;
            background-color: rgba(0, 0, 0, 0);

            &.permanent {
                font-size: 30px;
                background-color: rgba(0, 0, 0, 0.7);
            }
            &:hover {
                cursor: pointer;
                -webkit-transition: background-color 150ms linear;
                -ms-transition: background-color 150ms linear;
                transition: background-color 150ms linear;
                font-size: 30px;
                background-color: rgba(0, 0, 0, 0.7);
            }
            @media (max-width: 768px) {
                font-size: 12px;
                top: 70%;
                left: 70%;
                width: 26px;
                height: 26px;
                padding: 5px;
                border-radius: 100%;
                opacity: 1;
                background-color: ${({ theme }) => theme.color.white};
                color: ${({ theme }) => theme.color.primary};

                &.permanent {
                    background: ${({ theme }) => theme.color.white};
                    color: ${({ theme }) => theme.color.primary};
                    opacity: 1;
                    font-size: 12px;
                }
                &:hover {
                    background-color: ${({ theme }) => theme.color.white};
                    font-size: 12px;
                }
            }
        }

        span.gravatar {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: ${({ size }) => (size ? size.desktop * 0.305 + 'px' : '32px')};
            font-weight: bold;
            letter-spacing: 0.05rem;
            border: ${({ src, theme }) => (src ? 'none' : `1px solid ${theme.color.lightGray}`)};
            border-radius: 100%;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
            transition: opacity 0.3s ease; // Suaviza la transición de opacidad
            border: ${({ src, theme }) => (src ? 'none' : `1px solid ${theme.color.lightGray}`)};
            &.opacity {
                opacity: 0.7;
            }
            @media (max-width: 768px) {
                border-radius: 100%;
            }
        }
    }
`
