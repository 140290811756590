import styled from 'styled-components'
import { ToHeader } from '../components/ToHeader'
import { ReactNode } from 'react'
import { defaultTheme } from '../styles/themes/defaultTheme'

type IProps = {
    header?: {
        left?: ReactNode
        center?: ReactNode
        right?: ReactNode
    }
    main: ReactNode
}

export const HeaderLayout = ({ header, main }: IProps) => {
    const { left, center, right } = header ?? {}
    return (
        <StyledRegisterPage className="redesign">
            <section className="header-section">
                {header && <ToHeader left={left} center={center} right={right} />}
                <main className="column-center">{main}</main>
            </section>
        </StyledRegisterPage>
    )
}

export const StyledRegisterPage = styled.div`
    color: ${defaultTheme.color.primary};
    background-color: ${defaultTheme.color.offWhite};
    min-height: 100dvh;
    & > section {
        display: grid;
        grid-template-rows: auto 1fr;
        min-height: 100dvh;

        & > main {
            width: 386px;
            min-height: 100%;
            min-height: -webkit-fill-available;
            margin: 1em auto;
            padding: 30px;
            background-color: ${defaultTheme.color.white};
            border-radius: 20px;

            & form {
                display: grid;
                gap: 0.2em;
            }
            @media screen and (max-width: 768px) {
                max-width: 302px;
            }
            & > .main-content {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                gap: 2em;
                height: 100%;
                min-height: 90vh;
                padding: 2em 0;
            }
            & > .main-content > section > main {
                margin-bottom: 3em;
                & > p {
                    width: 70%;
                }
            }
        }

        .title {
            font-size: 18px;
            margin: 0;
        }

        strong {
            font-weight: 600;
        }

        button {
            width: 100%;
            max-width: 302px;
        }

        footer {
            & label select {
                font-size: 0.9rem;
                background-size: 2rem;
            }
        }
    }
`
