import { ComponentProps, useEffect, useState } from 'react'
import DayJS, { humanize } from '../../helpers/DayJS'

import { AssignationBar } from './AssignationBar'
import { GetTimelineResponse } from '../../infrastructure/api-egg'
import { Icon } from '../Icon'
import { handlePresent } from '../../helpers/userAttendance'
import { setNewAccessToken } from '../../infrastructure/apis'
import { useNavigate } from 'react-router-dom'
import { useOpenMeet } from '../../hooks/useOpenMeet'
import { useQueryClient } from '@tanstack/react-query'
import { useTimeline } from '../../hooks/useTimeline'
import { useTranslation } from 'react-i18next'
import { useKeyboard } from '../../hooks/useKeyboard'

/**
 * @see https://eggcooperation.atlassian.net/browse/EGG-872
 */
export const MeetButton = ({
    isInside,
    meetings,
    courseId,
    slug,
    nextClass,
    algorithmId,
    asyncCourse,
    offlineCourse,
    status,
    iconOnly,
    ...rest
}: ComponentProps<typeof AssignationBar> & { iconOnly?: boolean }) => {
    const { t, i18n } = useTranslation('dashboard')
    const navigate = useNavigate()
    const { openMeet, isOpening } = useOpenMeet(courseId)
    const { currentEvent, nextEvent } = useTimeline(courseId)
    const [btnLabel, setBtnLabel] = useState<BtnLabels | null>('Egg ®')
    const [isInRange, setIsInRange] = useState(!!nextClass?.isInRange)
    const queryClient = useQueryClient()

    const startSoon = DayJS(rest?.start).diff(DayJS(), 'hours') < 3 // User starts in less than 3 hours
    const isTeamsAssigned = !!algorithmId
    const hasNextClass = !!nextClass?.open && !!nextClass?.start
    const hasGeneralMeeting = !!meetings?.general
    const isLoading = btnLabel === 'Egg ®' || isOpening
    const useFirstStepAsGeneral = meetings?.useFirstStepAsGeneral

    useEffect(() => {
        if (isOpening) setBtnLabel('Opening...')
    }, [isOpening, t])

    // Set the button label to the time remaining to the next class
    useEffect(() => {
        const interval = setInterval(() => {
            if (!meetings || !nextClass) return setBtnLabel(null)

            const now = DayJS()
            const openTime = DayJS(hasGeneralMeeting || useFirstStepAsGeneral ? nextClass?.open : nextClass?.start)

            const endTime = DayJS(nextClass.end)
            setIsInRange(now.isBetween(openTime, endTime))
            let btnLabel: BtnLabels | null = null

            if (currentEvent?.room === null) btnLabel = 'Meet ended'
            else {
                if (isInRange) {
                    if (!isTeamsAssigned && !hasGeneralMeeting) btnLabel = useFirstStepAsGeneral ? 'LIVE Preview' : 'Forming teams...'
                    else if (currentEvent?.room === 'teams') btnLabel = 'Team LIVE'
                    else if (currentEvent?.room === 'general') btnLabel = 'General LIVE'
                } else btnLabel = t('Start {{ startIn }}', { startIn: humanize(openTime) })
            }

            if (nextEvent) {
                const { date } = nextEvent
                const diff = DayJS(date).diff(now)
                if (diff < 0)
                    queryClient.setQueryData<GetTimelineResponse['data']>(['Team', 'Timeline', courseId], old =>
                        old
                            ? {
                                  ...old,
                                  timeline: old.timeline.map(event => {
                                      if (event.date === currentEvent?.date)
                                          return {
                                              ...currentEvent,
                                              status: 'past',
                                          }
                                      return event
                                  }),
                                  currentEvent: {
                                      ...nextEvent,
                                      status: 'current',
                                  },
                                  nextEvent: undefined,
                              }
                            : old
                    )
            }

            if (btnLabel) setBtnLabel(btnLabel)
        }, 1000)
        return () => clearInterval(interval)
    }, [
        hasGeneralMeeting,
        i18n.resolvedLanguage,
        isInRange,
        isTeamsAssigned,
        meetings,
        nextClass,
        iconOnly,
        t,
        currentEvent?.room,
        nextEvent,
        queryClient,
        courseId,
        hasNextClass,
        useFirstStepAsGeneral,
        currentEvent,
    ])

    // Handle keyboard shortcuts
    useKeyboard([
        {
            key: 'l',
            onKeyPressed: isInside ? onClick : () => undefined,
        },
    ])

    if (
        (!isLoading && (!meetings || !nextClass)) ||
        offlineCourse ||
        asyncCourse ||
        !(status === 'studying' || (status === 'enrolled' && startSoon))
    )
        return null

    const disabled =
        isLoading ||
        !hasNextClass ||
        !meetings ||
        !isInRange ||
        (!isTeamsAssigned && !hasGeneralMeeting && !useFirstStepAsGeneral) ||
        currentEvent?.room === null

    function onClick() {
        if (disabled) return
        setNewAccessToken(rest['access-token'])
        handlePresent(courseId)
        return useFirstStepAsGeneral && !isTeamsAssigned
            ? navigate(`/${slug ?? courseId}?step=true`)
            : isInside
            ? openMeet()
            : navigate(`/${slug ?? courseId}?meet=true`)
    }

    return (
        <button className="primary custom-action" onClick={onClick} disabled={disabled} title={t('Live') + ' (l)'}>
            <Icon icon={['fas', 'circle-video']} size="lg" className="button-icon" />
            {!iconOnly && btnLabel ? t(btnLabel) : btnLabel}
        </button>
    )
}

type BtnLabels =
    | 'Egg ®'
    | 'Opening...'
    | 'Forming teams...'
    | 'Meet ended'
    | 'Start {{ startIn }}'
    | 'General LIVE'
    | 'Team LIVE'
    | 'LIVE Preview'
