import { useTranslation } from 'react-i18next'
import { EnrollProps } from './Enroll'
import { useParams } from 'react-router-dom'
import { FrequencyCard } from './FrequencyCard'
import { useFrequency } from '../hooks/useFrequency'
import styled from 'styled-components'
import { ButtonsBlock } from './ButtonsBlock'

export const FrequencyView = (props: EnrollProps) => {
    const { t } = useTranslation('enroll')
    const { ...rest } = props
    return (
        <>
            <div>
                <strong className="blue">
                    {t('Step {{ step }} of {{ steps }}', {
                        step: 1,
                        steps: 2,
                    })}
                </strong>
                <h1 className="title">{t('Choose your pace and class time.')}</h1>
                <p className="light">
                    {t('Simply select the time for the pace you want to attend.')}
                </p>
                <FrequenciesList {...props} />
            </div>
            <ButtonsBlock {...{ ...rest, step: 'frequency' }} />
        </>
    )
}

const FrequenciesList = ({ choices, isWidget }: EnrollProps) => {
    const { slug: networkId } = useParams()
    const { t } = useTranslation('enroll')
    const { frequencies, isLoading } = useFrequency(networkId)
    const [{ courseId }, setData] = choices

    if (isLoading) return <p>{t('Searching for the best options for you...')}</p>

    return (
        <>
            <FrequenciesListStyle listLength={frequencies?.length ?? 0} isWidget={isWidget}>
                {frequencies?.length ? (
                    <>
                        {frequencies.map(frequency => (
                            <FrequencyCard
                                key={frequency.id}
                                {...frequency}
                                onSelect={id => setData(prev => ({ ...prev, courseId: id }))}
                                selected={courseId}
                                checked={frequency.courses.some(({ id }) => id === courseId)}
                            />
                        ))}
                    </>
                ) : null}
            </FrequenciesListStyle>
        </>
    )
}

const FrequenciesListStyle = styled.div<{ listLength: number; isWidget?: boolean }>`
    display: grid;
    grid-template-columns: ${props => (props.isWidget ? '1fr' : '1fr 1fr')};
    -webkit-box-pack: justify;
    place-content: center;
    justify-items: center;
    gap: ${props => (props.listLength === 1 ? '0' : '1rem')};
    padding: 2em 0;
    & > div {
        width: 265px;
    }
    header {
        padding: 1em 2em;
    }
    main {
        padding: 2em;
        div {
            margin-bottom: 1em;
            .days {
                grid-auto-columns: 2.5em !important;
                grid-auto-rows: 2.5em !important;
                .day {
                    font-size: smaller;
                }
            }
        }
    }
    footer {
        padding: 1em 2em;
        div {
            label {
                font-size: 0.8em;
                select {
                    font-size: 1.2em;
                    padding: 1em;
                }
            }
        }
    }
    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        & > div {
            width: 100%;
        }
    }
`
