import { StaticInfoBlock } from '../../components/Content/StaticInfoBlock'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ModalPageLayout from '../../layouts/ModalPageLayout'
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

const FaqPage = () => {
    return (
        <ModalPageLayout>
            <h1>Tutoriales</h1>
            <h2>Los detalles importantes que necesitas tener siempre a mano.</h2>
            <StaticInfoBlock>
                <div className="block-content">
                    <div className="info-container">
                        <h4>¿Cómo ingreso al encuentro del día?</h4>
                        <h5>Si llegaste a tiempo al encuentro:</h5>
                        <p>
                            1. Presiona el botón &quot;Dar presente&quot;. <br /> 2. Haz click en el
                            icono de cámara <FontAwesomeIcon icon={['fas', 'video']} /> que te
                            llevará al Zoom de tu encuentro en vivo.
                        </p>
                        <video controls autoPlay loop>
                            <source
                                src={
                                    'https://egg-widget-script.s3.amazonaws.com/lxp-videos/1.mp4#t=0.10'
                                }
                                type="video/mp4"
                            />
                        </video>
                        <h5>Si llegaste a tarde al encuentro:</h5>
                        <p>
                            1. Presiona el botón “Ingresar” <br /> 2. Haz click en el icono de
                            cámara <FontAwesomeIcon icon={['fas', 'video']} /> que te llevará al
                            Zoom de tu encuentro en vivo.
                        </p>
                        <video controls autoPlay loop>
                            <source
                                src={
                                    'https://egg-widget-script.s3.amazonaws.com/lxp-videos/2.mp4#t=0.10'
                                }
                                type="video/mp4"
                            />
                        </video>
                    </div>
                </div>
            </StaticInfoBlock>
            <StaticInfoBlock>
                <div className="block-content">
                    <div className="info-container">
                        <h4>App de Egg para tus encuentros en vivo </h4>
                        <p>
                            Es importante que descargues la aplicación de Egg en Zoom para que tu
                            interacción durante los encuentros en vivo sea mucho más simple
                            (ahorrarás tiempo y esfuerzo). Observa cómo realizar la descarga:
                        </p>
                        <LiteYouTubeEmbed
                            id="vi1ERsFYjys"
                            title="Nuestra metodología | Egg"
                            playerClass="lty-playbtn"
                            wrapperClass="yt-lite"
                        />
                    </div>
                </div>
            </StaticInfoBlock>
            <StaticInfoBlock>
                <div className="block-content">
                    <div className="info-container">
                        <h4>¿Cuál es mi equipo del día?</h4>
                        <p>
                            Debajo de tu nombre verás el número del equipo con el que trabajarás
                            hoy.
                        </p>
                        <video controls autoPlay loop>
                            <source
                                src={
                                    'https://egg-widget-script.s3.amazonaws.com/lxp-videos/3.mp4#t=0.10'
                                }
                                type="video/mp4"
                            />
                        </video>
                    </div>
                </div>
            </StaticInfoBlock>
            <StaticInfoBlock>
                <div className="block-content">
                    <div className="info-container">
                        <h4>¿Cómo veo mi progreso?</h4>
                        <p>
                            Haz click en tu nombre. Allí te encontrás con tus asistencias, pulsos y
                            cantidad de veces que fuiste facilitador/a.
                        </p>
                        <video controls autoPlay loop>
                            <source
                                src={
                                    'https://egg-widget-script.s3.amazonaws.com/lxp-videos/4.mp4#t=0.10'
                                }
                                type="video/mp4"
                            />
                        </video>
                    </div>
                </div>
            </StaticInfoBlock>
            <StaticInfoBlock>
                <div className="block-content">
                    <div className="info-container">
                        <h4>¿Cómo cargar mi foto de perfil?</h4>
                        <p>
                            Haz click en tu nombre y allí podrás cargar tu foto. Ten en cuenta que
                            la foto debe indentificarte para que tu equipo pueda reconocerte
                            facilmente.
                        </p>
                        <video controls autoPlay loop>
                            <source
                                src={
                                    'https://egg-widget-script.s3.amazonaws.com/lxp-videos/5.mp4#t=0.10'
                                }
                                type="video/mp4"
                            />
                        </video>
                    </div>
                </div>
            </StaticInfoBlock>
            <StaticInfoBlock>
                <div className="block-content">
                    <div className="info-container">
                        <h4>¿Cómo pido ayuda externa?</h4>
                        <p>
                            Si en tu equipo existen dudas sin resolver, puedes solicitar ayuda
                            externa, lo que hará que un mentor ingrese a tu equipo para brindar
                            asistencia. Para ello, deberás presionar el botón{' '}
                            <strong>“solicitar ayuda”</strong> y aguardar la llegada del mentor/a.
                        </p>
                        <p>
                            Si deseas desestimar el pedido de ayuda antes de que llegue el mentor,
                            haz click en <strong>“cancelar solicitud de ayuda”.</strong>{' '}
                        </p>
                        <video controls autoPlay loop>
                            <source
                                src={
                                    'https://egg-widget-script.s3.amazonaws.com/lxp-videos/6.mp4#t=0.10'
                                }
                                type="video/mp4"
                            />
                        </video>
                    </div>
                </div>
            </StaticInfoBlock>
            <StaticInfoBlock>
                <div className="block-content">
                    <div className="info-container">
                        <h4>¿Qué hago si me llaman para resolver dudas?</h4>
                        <p>
                            Si eres mentor/a serás asignado por un coach para resolver dudas de un
                            equipo. Verás el equipo que te solicitó y si no puedes resolver las
                            dudas solo tú podras escalar el pedido de ayuda y pedir otro mentor/a.
                            Una vez que hayas terminado podrás volver a tu equipo haciendo click en{' '}
                            <strong>“Salir”</strong>.
                        </p>
                        <video controls autoPlay loop>
                            <source
                                src={
                                    'https://egg-widget-script.s3.amazonaws.com/lxp-videos/7.mp4#t=0.10'
                                }
                                type="video/mp4"
                            />
                        </video>
                    </div>
                </div>
            </StaticInfoBlock>
        </ModalPageLayout>
    )
}

export default FaqPage
