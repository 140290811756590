import { useQuery } from '@tanstack/react-query'
import { UserRankingParams, getUserRanking } from '../../../infrastructure/api-ranking'
import { CourseByUser } from '../../../interfaces/api-ranking'

export const useUserRanking = (filters: UserRankingParams, courses?: CourseByUser[]) => {
    const queryKey = ['Ranking', 'Position', filters]

    const { data: userRanking, ...rest } = useQuery({
        queryKey,
        queryFn: () => getUserRanking(filters),
        enabled: Boolean(filters) && Boolean(courses),
    })

    return {
        userRanking,
        ...rest,
    }
}
