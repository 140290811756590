import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { Card } from '../../../components/CardWithCheck'
import DayJS from '../../../helpers/DayJS'
import { FrequencyDTO } from '../api-marketplace'
import { generateTime } from '../../../helpers/dateFormatters'

export const FrequencyCard = ({ ...props }: FrequencyCardProps) => {
    return (
        <>
            <Card
                checked={props.checked}
                header={<FrequencyCardHeader {...props} />}
                main={
                    <>
                        <FrequencyDays {...props} />
                    </>
                }
                footer={<FrequencyCardFooter {...props} />}
                styles={{
                    footer: {
                        backgroundColor: '#0037501A',
                    },
                }}
            />
        </>
    )
}

const FrequencyCardHeader = ({ title }: Pick<FrequencyCardProps, 'title'>) => {
    const { t } = useTranslation('enroll')

    return (
        <div style={{ display: 'flex', gap: '1em' }}>
            <FontAwesomeIcon
                icon={['fal', title === 'Intensive' ? 'gauge-max' : 'gauge']}
                style={{ fontSize: 40 }}
            />
            <div>
                <div style={{ fontSize: '10px', fontWeight: 'bold', textTransform: 'uppercase' }}>
                    {t('Rhythm')}
                </div>
                <div style={{ fontSize: '17px', fontWeight: '500' }}>{t(title)}</div>
            </div>
        </div>
    )
}

const FrequencyDays = ({ days }: Pick<FrequencyCardProps, 'days'>) => {
    const { t } = useTranslation('enroll')

    return (
        <div>
            <div style={{ marginBottom: 13, fontWeight: 500 }}>
                <small>{t('Course days')}</small>
            </div>
            <div
                className="days"
                style={{
                    display: 'grid',
                    gap: '10px',
                    gridAutoColumns: '43px',
                    gridAutoRows: '43px',
                    gridAutoFlow: 'column',
                    textTransform: 'uppercase',
                    fontWeight: '600',
                }}
            >
                {days.map(day => (
                    <span
                        key={day}
                        className="day"
                        style={{
                            display: 'grid',
                            placeContent: 'center',
                            border: '1px solid #DEDEDE',
                            borderRadius: '12px',
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        {t(DayJS().day(day).format('ddd').replace('.', ''))}
                    </span>
                ))}
            </div>
        </div>
    )
}

const FrequencyCardFooter = ({
    id,
    checked,
    courses,
    selected,
    onSelect,
}: Pick<FrequencyCardProps, 'courses' | 'id' | 'onSelect' | 'checked' | 'selected'>) => {
    const { t } = useTranslation('enroll')
    return (
        <div>
            <label>
                <select
                    name={id}
                    id={id}
                    value={!checked ? '' : selected}
                    style={{ width: '-moz-available' }}
                    onChange={({ target: { value } }) => onSelect(value)}
                >
                    <option value="" disabled>
                        {t('Select a time')}
                    </option>
                    {courses.map(({ id, from, to, fromMinutes, toMinutes }) => {
                        return (
                            <option value={id} key={id}>
                                {t(
                                    'From {{ start }} to {{ end }} hs',
                                    generateTime(from, to, fromMinutes, toMinutes)
                                )}
                            </option>
                        )
                    })}
                </select>
            </label>
        </div>
    )
}

type FrequencyCardProps = FrequencyDTO & {
    selected?: string
    onSelect: (id: string) => void
    checked: boolean
}
