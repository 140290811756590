import {
    API_ERROR,
    API_RESPONSE,
    DeleteAuthResponse,
    GetGoogleCalendarLinkResponse,
    GetUserCoursesResponse,
    GetUserInfoResponse,
    PostAuthBody,
    PostAuthResponse,
    PostRefreshToken,
    ValidateCredentialsProps,
    ValidateCredentialsResponse,
    WhoAmIResponse,
} from '../interfaces/api-activation'
import Axios from 'axios'
import { refreshInterceptor, setNewAccessToken } from './apis'
import { GACategory, SendGAEvent } from '../helpers/GAEvents'

import DayJS from '../helpers/DayJS'
import { fbSignOut } from './firebase'
import { extendCourseData } from '../helpers/courses'

const { REACT_APP_SIGNUP_API } = process.env

if (!REACT_APP_SIGNUP_API) console.error('REACT_APP_SIGNUP_API to be declared on your system environments variables')

const baseURL = `${REACT_APP_SIGNUP_API}`

export const ApiSignup = Axios.create({
    baseURL,
})

const tz = DayJS.tz.guess()

ApiSignup.interceptors.response.use(
    response => response,
    error => refreshInterceptor(error, ApiSignup)
)

export const getWhoAmI = (data: { email: string; captcha_token: string }) =>
    ApiSignup.post<WhoAmIResponse>(`/v1/users/who-am-i`, data).then(({ data }) => data.data)

export const signInWithCredentials = (data: SignInWithCredentialsParams) =>
    ApiSignup.post<API_RESPONSE<object>>(`/v1/auth/credentials`, data).then(({ data }) => data.data)

export const getUserInfo = () => ApiSignup.post<GetUserInfoResponse>('/v1/users/me', { tz }).then(({ data }) => data.data)

export const getUserCourses = () =>
    ApiSignup.get<GetUserCoursesResponse>('/v1/users/courses').then(({ data }) => data.data.courses.map(extendCourseData))

export const validateCredentials = ({ captcha_token, ...rest }: ValidateCredentialsProps) =>
    ApiSignup.post<ValidateCredentialsResponse>(`/v1/auth`, { captcha_token, ...rest }).then(({ data }) => {
        SendGAEvent(GACategory.LOGIN, `Login`)
        const token = data.data['access-token']
        token && setNewAccessToken(token)
        return data.data
    })

export const postAuth = (data: PostAuthBody) =>
    ApiSignup.post<PostAuthResponse>(`/v1/auth`, data, {
        withCredentials: true,
    }).then(({ data }) => {
        SendGAEvent(GACategory.LOGIN, `Login`)
        const token = data.data['access-token']
        token && setNewAccessToken(token)
        return data.data
    })

export const refreshAccessToken = () =>
    Axios.get<PostRefreshToken>(`${baseURL}/v1/auth`, {
        withCredentials: true,
    })
        .then(({ data }) => (data['access-token'] ? data['access-token'] : null))
        .catch(({ code }: API_ERROR) => {
            // eslint-disable-next-line no-restricted-globals
            console.log('code', code)
            if (code === 403) window.location.href = '/logout'
            return null
        })

export const clearSession = () =>
    ApiSignup.delete<DeleteAuthResponse>(`/v1/auth`, {
        withCredentials: true,
    }).then(({ data }) => {
        SendGAEvent(GACategory.EXIT, `Logout`)
        setNewAccessToken(null)
        console.info(data.message)
    })

export const signOut = () =>
    ApiSignup.delete('/v1/auth', {
        withCredentials: true,
    }).then(() => {
        delete ApiSignup.defaults.headers.common.Authorization
        fbSignOut()
    })

export const authTest = () => ApiSignup.get('/v1/auth/check')

export const googleCalendarLink = (profileId: string, captcha_token: string) =>
    ApiSignup.post<GetGoogleCalendarLinkResponse>(`/v1/servicies/${profileId}/calendarLink`, {
        captcha_token,
    }).then(({ data }) => data.data)

export const changePassword = ({ token, ...rest }: ChangePasswordParams) =>
    ApiSignup.post(`/v1/auth/password-change`, rest, {
        headers: { Authorization: `Bearer ${token}` },
    })

export const resetPassword = (data: ResetPasswordParams) =>
    ApiSignup.post<ResetPasswordResponse>(`/v1/auth/password-reset`, data).then(({ data }) => data.data)

export const getOrganizationBranding = (organizationId: string) =>
    ApiSignup.get<GetOrganizationBrandingResponse>(`/v1/organizations/${organizationId}/branding`).then(({ data }) => data.data)

type SignInWithCredentialsParams = {
    captcha_token: string
    email: string
    password: string
}

interface ChangePasswordParams {
    password: string
    token: string
}

type ResetPasswordParams = {
    email: string
    captcha_token: string
}

type ResetPasswordResponse = API_RESPONSE<{ message: string; email: string; _id: string }>

type GetOrganizationBrandingResponse = API_RESPONSE<OrganizationBranding>

export interface OrganizationBranding {
    id: string
    logo: string
    headerImage: null
    actionButtonColor: string
    accentColor: string
    cardBackground: string
    cardFontColor: string
    createdAt: string
    updatedAt: string
    organizationId: string
}
