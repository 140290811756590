import { ContentStepDTO, PlanDTO, StudyPlanStatus } from '../infrastructure/api-widget'

export const studyPlanParser = (data: APIStudyPlanDTO): PlanDTO => {
    const status = 'inProgress' as StudyPlanStatus
    const { plan: courseStudyPlan, courseId } = data

    const allSessions = courseStudyPlan.modules.flatMap(module =>
        module.sections.flatMap(section => ({ ...section, moduleId: module._id }))
    )

    const sessions = allSessions.map((session, sectionIdx) => ({
        id: session._id,
        title: session.title,
        number: sectionIdx + 1,
        status,
        steps: session.steps.map((step, stepIdx) => ({
            id: step._id,
            title: step.title,
            status,
            prevStep:
                stepIdx === 0 && sectionIdx === 0
                    ? undefined
                    : stepIdx > 0
                    ? session.steps.at(stepIdx - 1)?._id
                    : allSessions.at(sectionIdx - 1)?.steps.at(-1)?._id ?? undefined,
            nextStep:
                stepIdx === session.steps.length - 1 && sectionIdx === allSessions.length - 1
                    ? undefined
                    : stepIdx < session.steps.length - 1
                    ? session.steps.at(stepIdx + 1)?._id
                    : allSessions.at(sectionIdx + 1)?.steps.at(0)?._id ?? undefined,
        })),
    }))

    const id: string = courseStudyPlan._id

    const plan = { id, courseId, sessions }
    return plan
}

export function unLockPlan({ plan, userDaySession }: { plan?: PlanDTO; userDaySession?: string }): PlanDTO | undefined {
    if (!plan) return undefined

    let status: StudyPlanStatus = !userDaySession ? 'unknown' : 'done'
    let blocked = false
    const unlockedPlan = {
        ...plan,
        sessions: plan.sessions.map((session, idx) => {
            if (blocked) status = 'blocked'
            else if (session.id === userDaySession) {
                status = 'inProgress'
                blocked = true
                if (session.steps.at(-1)) session.steps.at(-1)!.nextStep = undefined
            }

            return {
                ...session,
                status,
                steps: session.steps.map(step => ({
                    ...step,
                    status,
                })),
            }
        }),
    }

    return unlockedPlan
}

// Interface
export interface APIStudyPlanDTO {
    courseId: string
    plan: {
        _id: string
        title: string
        type: string
        createdAt: string
        updatedAt: string
        modules: {
            _id: string
            title: string
            type: string
            sections: {
                _id: string
                title: string
                type: string
                steps: ContentStepDTO[]
            }[]
        }[]
    }
}
