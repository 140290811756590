import { useTranslation } from 'react-i18next'
import { ReactComponent as GoogleLogo } from '../assets/google_logo.svg'
import styled from 'styled-components'
import React from 'react'
import { defaultTheme } from '../styles/themes/defaultTheme'

export const GoogleButton = ({ label, ...rest }: GoogleButtonProps) => {
    const { t } = useTranslation('signIn')
    return (
        <Button {...rest}>
            <GoogleLogo title={label ?? t('Start with Google')} width={40} height={40} />
            {label ?? t('Start with Google')}
        </Button>
    )
}

const Button = styled.button`
    border-radius: ${defaultTheme.borderRadius};
    border: 1px solid ${defaultTheme.color.primary};
    color: rgb(0, 55, 80);
    font-size: 1rem;
    font-weight: 700;
    background-color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    gap: 1em;
    align-items: center;
    padding: 7px 2em 7px 1em !important;
    width: -webkit-fill-available;
    width: 100%;

    &:hover {
        text-decoration: underline;
        box-shadow: rgb(0, 55, 80) 0px 0px 5px -2px;
    }

    &:disabled {
        filter: grayscale();
        text-decoration: none;
    }
`

interface GoogleButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    label?: string
}
