import styled from 'styled-components'
import { Modal } from './Modal'
import { IframeHTMLAttributes, ReactNode } from 'react'

export const OpenUrl = ({
    url,
    header,
    footer,
    props,
}: {
    url?: string
    header?: ReactNode
    footer?: ReactNode
    props?: IframeHTMLAttributes<HTMLIFrameElement>
}) => {
    if (!url) return <h3>Content not found</h3>

    return (
        <Modal>
            {header}
            <Frame src={url} width={23} {...props} />
            {footer}
        </Modal>
    )
}

const Frame = styled.iframe`
    width: calc(-13em + 100dvw);
    height: calc(-20em + 100dvh);
    border: 0;
    padding-right: 1.5em;
`
