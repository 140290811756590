import { Navigate, useParams } from 'react-router-dom'
import { useUserCourse } from '../hooks/queries/useUserCourses'

import { useTranslation } from 'react-i18next'

export const Dashboard = () => {
    const { t } = useTranslation('content')

    const { slugOrId } = useParams()
    const { courseSlug, isLoading } = useUserCourse(slugOrId)

    const url = `/${courseSlug || courseSlug}`

    if (isLoading) return <p>{t('Redirecting to course...')}</p>

    return <Navigate to={url} />
}
