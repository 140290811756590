export const SkeletonTextLine = ({ repeat: length = 1 }: { repeat?: number }) => (
    <>
        {Array.from({ length }).map((_, i) => (
            <p
                key={i}
                className="skeleton"
                style={{
                    height: '1.5rem',
                }}
                children=" "
            />
        ))}
    </>
)
