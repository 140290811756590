import styled from 'styled-components'
import { BirthDate } from '../FormElements/BirthDate'
import { ChangeEvent, useEffect, useState } from 'react'
import { Country } from '../FormElements/Country'
import { Gender } from '../FormElements/Gender'
import { Input } from '../FormElements/Input'
import { isValidPhone, PhoneNumber } from '../FormElements/PhoneNumber'
import { Modal } from './Modal'
import { Province } from '../FormElements/Province'
import { useUpdateUser } from '../../hooks/queries/useUpdateUser'
import { useTranslation } from 'react-i18next'
import { useUIContext } from '../../context/UIContext'
import { useUser } from '../../hooks/queries/useUser'
import { DefaultUserDTO } from '../../interfaces/api-activation'
import { useLanguages } from '../../hooks/useLanguages'
import { Select } from '../FormElements/Select'

export const MyProfileDataModal = () => {
    const { t } = useTranslation('profile')
    const { modalClose, setIsFullScreen } = useUIContext()
    const { user, isLoading } = useUser()
    const { i18n } = useTranslation()
    const { langs, userLang } = useLanguages()
    const { update, api_error } = useUpdateUser()
    const [userData, setUserData] = useState<DefaultUserDTO>()
    const [changes, setChanges] = useState<Partial<DefaultUserDTO>>({})
    const [, setLanguageSelect] = useState(false)

    const isLegacyUser = user?.isLegacyUser
    const legacyUserWarning = isLegacyUser ? `(${t('Disabled for this user')})` : ''

    useEffect(() => {
        setIsFullScreen(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!user) return
        setUserData(user)
    }, [user])

    const onChange = ({ target: { name, value } }: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
        if (name === 'countryId') setChanges(prev => ({ ...prev, provinceId: undefined })) // Reset province when country changes
        setChanges(prev => ({
            ...prev,
            [name]: name === 'countryId' || name === 'provinceId' ? +value : value,
        }))
    }

    const onSelectLanguage = () => setLanguageSelect(true)

    const onSave = () => {
        update(changes)
        modalClose()
        setIsFullScreen(false)
    }

    const haveChanges = Object.keys(changes).length > 0

    const { name, displayName, gender, lastname, dni, phone } = userData ?? {}

    const countryId = changes?.countryId ?? userData?.countryId ?? ''

    return (
        <Modal>
            <ModalStyled>
                <h2 className="title">{t('Personal information')}</h2>

                <div className="columns">
                    <div className="column">
                        <Input
                            {...{
                                label: name,
                                id: 'name',
                                name: 'name',
                                defaultValue: name ?? '',
                                onChange,
                                autoComplete: 'given-name',
                                api_error,
                            }}
                        />

                        <br />

                        <Input
                            {...{
                                label: t('Alias') + legacyUserWarning,
                                id: 'displayName',
                                name: 'displayName',
                                defaultValue: displayName ?? '',
                                disabled: isLegacyUser,
                                onChange,
                                autoComplete: 'off',
                                api_error,
                            }}
                        />
                        <br />

                        <Country
                            {...{
                                value: countryId,
                                onChange,
                                api_error,
                            }}
                        />
                        <br />

                        <Gender
                            {...{
                                value: changes.gender ?? gender ?? '',
                                onChange,
                                api_error,
                            }}
                        />
                        <br />

                        <Input
                            {...{
                                label: t('Company'),
                                id: 'referrer',
                                name: 'referrer',
                                defaultValue: user?.referrer,
                                disabled: true,
                                api_error,
                            }}
                        />
                        <br />

                        <Input
                            {...{
                                label: t('Email'),
                                id: 'email',
                                name: 'email',
                                defaultValue: user?.email,
                                disabled: true,
                                api_error,
                            }}
                        />
                        <br />
                    </div>

                    <div className="column">
                        <Input
                            {...{
                                label: t('Surname'),
                                id: 'lastname',
                                name: 'lastname',
                                defaultValue: lastname ?? '',
                                onChange,
                                autoComplete: 'family-name',
                                api_error,
                            }}
                        />
                        <br />

                        <Input
                            {...{
                                label: t('Identity document DNI/RUT'),
                                id: 'dni',
                                name: 'dni',
                                defaultValue: dni ?? '',
                                onChange,
                                api_error,
                            }}
                        />
                        <br />

                        <Province
                            {...{
                                value: changes.provinceId ?? userData?.provinceId ?? '',
                                onChange,
                                countryId: countryId || undefined,
                                api_error,
                            }}
                        />
                        <br />

                        <BirthDate
                            {...{
                                value: changes.dateBirth ?? userData?.dateBirth ?? '',
                                onChange,
                                api_error,
                            }}
                        />
                        <br />

                        <PhoneNumber
                            {...{
                                value: changes.phone ?? (userData?.phone as any) ?? undefined,
                                onChange: (phone: any) => setUserData(prev => (prev ? { ...prev, phone: phone ?? null } : prev)),
                                error: phone && !isValidPhone(phone) ? t('Invalid phone number') : undefined,
                                onBlur: () => setChanges(prev => ({ ...prev, phone })),
                            }}
                        />
                        <br />
                        <Select
                            label={t('Preferred language')}
                            id="language"
                            name="language"
                            autoComplete="off"
                            onChange={({ target: { value } }) => {
                                i18n.changeLanguage(value)
                                onSelectLanguage()
                            }}
                            value={userLang.resolvedLanguage}
                        >
                            {Object.entries(langs ?? {}).map(([key, value]) => (
                                <option key={key} value={key}>
                                    {value.nativeName}
                                </option>
                            ))}
                        </Select>
                    </div>
                </div>

                <br />
                <div className="buttons">
                    <button className="outlined" onClick={modalClose}>
                        {t('Exit')}
                    </button>
                    <button className="primary" disabled={isLoading || !haveChanges} onClick={onSave}>
                        {t('Save')}
                    </button>
                </div>
            </ModalStyled>
        </Modal>
    )
}

const ModalStyled = styled.main`
    overflow: auto;
    height: -webkit-fill-available;
    height: 100vh;
    max-height: calc(100vh - 180px);
    padding: 1.5em;
    .title {
        margin: 0;
        margin-bottom: 1em;
    }
    .columns {
        display: flex;
        gap: 20px;

        .column {
            display: inline-block;
            width: 363px;
        }

        .column-big {
            width: 100%;
        }
    }

    @media screen and (max-width: 768px) {
        overflow: auto;
        max-height: 100vh;
        margin: 0;
        padding: 2em;
        padding-bottom: 6em;
        .columns {
            flex-direction: column;
            .column {
                width: auto;
            }
        }
        .buttons {
            justify-content: center !important;
            button {
                width: -webkit-fill-available;
                width: 100%;
            }
        }
    }
`
