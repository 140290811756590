import React, { FocusEventHandler, useEffect, useState } from 'react'
import { ErrorsType, testPasswordStrength } from '../../helpers/validators'
import { PasswordInput } from './PasswordInput'
import { useTranslation } from 'react-i18next'
import { isProduction } from '../../interfaces/constants'
import { PostCreateUser } from '../../pages/register/api-register'
import DayJS from '../../helpers/DayJS'

export const PasswordConfirmation = ({ changes, setChanges, setValidPasswords, autoFocus }: PasswordConfirmationProps) => {
    const { t } = useTranslation('signUp')
    const { password = '' } = changes

    const [passwordConfirmation, setPasswordConfirmation] = useState('')
    const [passwordErrors, setPasswordErrors] = useState<ErrorsType[]>(
        testPasswordStrength(password, {
            length: 6,
            lowercase: true,
            uppercase: true,
        })
    )

    const arePasswordsValid = passwordErrors.every(({ isValid }) => isValid)
    const areConfirmationAndPasswordMatching = !!password && !!passwordConfirmation && password === passwordConfirmation
    const [confirmPasswordErrors, setConfirmPasswordErrors] = useState<ErrorsType[]>([
        {
            type: 'equalsPasswords',
            message: 'Passwords do not match.',
            isValid: areConfirmationAndPasswordMatching,
        },
    ])

    const handlePasswordChange: FocusEventHandler<HTMLInputElement> = ({ target: { name, value } }) => {
        setPasswordErrors(testPasswordStrength(value, { length: 6, lowercase: true, uppercase: true }))
        setChanges({ ...changes, [name]: value })
    }

    useEffect(() => {
        setConfirmPasswordErrors(
            confirmPasswordErrors.map(error => ({
                ...error,
                isValid: areConfirmationAndPasswordMatching,
            }))
        )
        setValidPasswords(arePasswordsValid && areConfirmationAndPasswordMatching)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [password, passwordConfirmation])

    useEffect(() => {
        setPasswordErrors(passwordErrors.map(error => ({ ...error, isValid: true })))
        setConfirmPasswordErrors(confirmPasswordErrors.map(error => ({ ...error, isValid: true })))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <PasswordInput
                {...{
                    defaultValue: password,
                    label: t('Password'),
                    id: 'password',
                    name: 'password',
                    autoComplete: 'new-password',
                    onChange: handlePasswordChange,
                    errors: passwordErrors,
                    pattern: '^(?=.*[a-z])(?=.*[A-Z]).{6,}$',
                    autoFocus,
                }}
            />
            <br />
            <PasswordInput
                {...{
                    defaultValue: passwordConfirmation,
                    label: t('Password confirmation'),
                    id: 'confirm_password',
                    name: 'confirm_password',
                    autoComplete: 'new-password',
                    pattern: '^(?=.*[a-z])(?=.*[A-Z]).{6,}$',
                    onChange: ({ target: { value } }) => setPasswordConfirmation(value),
                    errors: confirmPasswordErrors,
                    onDoubleClick: () => {
                        if (!isProduction) {
                            const fakeUser = randomFakeUser()
                            fakeUser?.password && setPasswordConfirmation(fakeUser.password)
                            setChanges({ ...changes, ...fakeUser })
                        }
                    },
                }}
            />
        </>
    )
}

const randomFakeUser = (): Partial<PostCreateUser> => {
    const ext = '@mail.com'
    const time = DayJS().format('YYYYMMDD_HHmmss')
    const email = `${time}${ext}`
    const termsAndConditions = true
    const password = '#@VYNtqDguFe8Wb'
    navigator?.clipboard?.writeText(email)
    return { email, password, termsAndConditions }
}

export interface PasswordConfirmationProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    password: string
    changes: Partial<PostCreateUser>
    setChanges: (value: Partial<PostCreateUser>) => void
    setValidPasswords: (value: boolean) => void
}
