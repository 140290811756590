import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { Icon } from '../../../../components/Icon'
import { EcosystemCourseDTO } from '../../../marketplace/api-marketplace'

export const CourseCard = ({ id, title, tags, description, slug, lang }: EcosystemCourseDTO) => {
    const { t } = useTranslation('enroll')
    const navigate = useNavigate()

    const __html = description ?? ''

    return (
        <article className="card light" onClick={() => navigate(`/way/course/${slug ?? id}`)}>
            <div className="top-tags"></div>
            <h1 className="title">{t(title)}</h1>
            <div className="tags">
                <img src={`/images/flag.${lang}.svg`} alt={t(lang)} />
                {tags?.map(({ name, color }, index) => (
                    <Tag key={index + name} color={color}>
                        {name}
                    </Tag>
                ))}
            </div>
            <div className="description" dangerouslySetInnerHTML={{ __html }}></div>
            <footer>
                <button className="icon display-flex">
                    {t('See more')} <Icon icon={['fas', 'arrow-right']} />
                </button>
            </footer>
        </article>
    )
}

export const CourseCardSkeleton = () => {
    return (
        <article className="card light">
            <div className="top-tags"></div>
            <h1 className="title skeleton"> </h1>
            <div className="tags"></div>
            <div className="description skeleton"></div>
            <footer>
                <button className="text skeleton"></button>
            </footer>
        </article>
    )
}

export const Tag = styled.div<{ color?: string }>`
    display: inline-block;
    color: white;
    font-weight: bold;
    background-color: ${({ color }) => color ?? '#003750'};
    border-radius: 6px;
    padding: 3px 10px;
    font-size: 0.86em;
`
