import { GetTimelineResponse } from '../infrastructure/api-egg'
import { useQueryClient } from '@tanstack/react-query'
import { handlePresent } from '../helpers/userAttendance'
import { useToken } from './useToken'

export const useActions = () => {
    const queryClient = useQueryClient()
    const { _id } = useToken()
    const actionDispatcher = ({ event, payload }: ActionsTypes) => {
        process.env.NODE_ENV === 'development' && console.log({ event, payload })
        // if has payload and scope does not include 'app', return
        if (payload && 'scope' in payload && payload?.scope?.length && !payload.scope?.includes('app')) return
        try {
            switch (event) {
                case 'ui-team-has-updated': {
                    queryClient.invalidateQueries({ queryKey: ['User', _id, 'Courses'] })
                    queryClient.invalidateQueries({ queryKey: ['User', _id, 'My Team'] })
                    const courseId = payload?.courseId

                    // Set present when teams has been assigned if required
                    if (courseId && sessionStorage.getItem(`require-present-${courseId}`)) handlePresent(courseId)

                    return
                }

                case 'ui-content-has-updated': {
                    queryClient.invalidateQueries({
                        queryKey: ['Study Plan', payload.courseId],
                        exact: false,
                    })
                    return
                }

                case 'new-timeline-event': {
                    const { _courseId } = payload
                    queryClient.setQueryData(['Team', 'Timeline', _courseId], payload)
                    return
                }

                case 'ui-reload': {
                    window.location.reload()
                    return
                }

                case 'user-joined': {
                    return
                }

                default:
                    console.warn('❗ Action not found:', { type: event, payload })
                    return
            }
        } catch (error) {
            console.error('❗ Error in actionDispatcher:', { type: event, payload, error })
        }
    }

    return { actionDispatcher }
}

type ActionsTypes =
    | {
          event: 'ui-team-has-updated'
          payload?: {
              scope?: string[]
              courseId: string
              name: string
              _algorithmId?: string
          }
      }
    | {
          event: 'ui-content-has-updated'
          payload: {
              scope?: string[]
              courseId: string
          }
      }
    | {
          event: 'new-timeline-event'
          payload: GetTimelineResponse['data']
      }
    | {
          event: 'ui-reload'
          payload?: undefined
      }
    | {
          event: 'user-joined'
          payload: {
              scope?: string[]
              _id: string
              _profileId: string
          }
      }
