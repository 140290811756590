import DayJS from './DayJS'
import { capitalize } from './formatters'

export const parseDateFromViewToApi = (date: string) => DayJS(date).toISOString()

// martes 18 de abril
export const parseDateToDDDD_D_de_MMMM_HH = (date: string) => capitalize(DayJS(date).format('LLLL'))
export const parseDateToDDDD_D_de_MMMM = (date: string) => capitalize(DayJS(date).format('LL'))

export const parseHourWidthMinutes = (hour: number, minutes: number) => {
    const localTimezone = DayJS.tz.guess()
    const time = DayJS().utc().hour(hour).minute(minutes).second(0).tz(localTimezone)
    return time.format('H:mm')
}

export const generateTime = (from: number, to: number, fromMinutes: number, toMinutes: number) => ({
    start: parseHourWidthMinutes(from, fromMinutes),
    end: parseHourWidthMinutes(to, toMinutes),
})

export const rtf = new Intl.RelativeTimeFormat(undefined, {
    localeMatcher: 'best fit',
    numeric: 'auto',
    style: 'long',
})
