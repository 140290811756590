import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createProfile } from '../api-marketplace'
import { useToken } from '../../../hooks/useToken'

export const useCreateProfile = () => {
    const queryClient = useQueryClient()
    const { _id } = useToken()

    const { mutate: create, ...rest } = useMutation({
        mutationFn: createProfile,
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['Ecosystem'], exact: false })
            queryClient.invalidateQueries({
                queryKey: ['User', _id, 'Courses'],
            })
        },
    })

    return { create, ...rest }
}
