import { getWhoAmI } from '../../infrastructure/api-signup'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { useCallback, useEffect, useState } from 'react'

export const useWhoAmI = ({ email, onError }: Options) => {
    const navigate = useNavigate()
    const { executeRecaptcha } = useGoogleReCaptcha()
    const [captcha_token, setCaptcha_token] = useState<string>()
    const queryKey = ['Who am I', email]

    // Get the captcha token
    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available')
            return
        }

        const token = await executeRecaptcha('useWhoAmI')
        setCaptcha_token(token)
    }, [executeRecaptcha])

    // Trigger the verification as soon as the component being loaded
    useEffect(() => {
        handleReCaptchaVerify()
    }, [handleReCaptchaVerify])

    const { data: iAm, ...rest } = useQuery({
        queryKey,
        queryFn: async () => {
            try {
                if (!captcha_token) throw new Error('Captcha token not available')

                const whoIAm = await getWhoAmI({
                    email: `${email}`,
                    captcha_token: `${captcha_token}`,
                })
                navigate(whoIAm.goTo)
                return whoIAm
            } catch (error: any) {
                onError?.(error)
                window.location.reload()
            }
        },
        enabled: false,
        retry: false,
    })

    return { iAm, ...rest }
}

type Options = {
    email?: string
    onError?: (message: string) => void
}
