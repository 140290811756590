import { useTranslation } from 'react-i18next'
import { useCountries } from '../../hooks/queries/useCountries'
import { SelectProps, Select } from './Select'

export const Country = (props?: SelectProps) => {
    const { t } = useTranslation('signUp')
    const { countries, status } = useCountries()

    return (
        <Select
            {...{
                label: t('Country'),
                id: 'countryId',
                name: 'countryId',
                autoComplete: 'country',
                ...props,
            }}
        >
            <option value="" disabled>
                {status === 'pending' ? t('Loading...') : t('Select your country')}
            </option>
            {countries?.map(({ id, name }) => (
                <option key={id} value={id} children={name} />
            ))}
        </Select>
    )
}
