import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useDetails } from '../hooks/useDetails'
import { defaultTheme } from '../../../styles/themes/defaultTheme'
import { SessionStepList } from '../../../components/Content/SessionStepList'

export const CourseDetails = () => {
    const navigate = useNavigate()
    const { slug } = useParams()
    const { t } = useTranslation('courseDetails')
    const { details } = useDetails(slug)

    if (!details) return null

    const { studyPlan, longDescription, title, tags, lang } = details

    const __html = longDescription ?? undefined

    return (
        <CourseDetailsStyle>
            <article className="card">
                <header>
                    <Tags>
                        <img src={`/images/flag.${lang}.svg`} alt={t(lang)} />
                        {tags.map(({ color, name }) => (
                            <Label key={name} color={color}>
                                {name}
                            </Label>
                        ))}
                    </Tags>
                </header>
                <main>
                    <div className="title">{title}</div>
                </main>
            </article>
            <div className="only-mobile">
                <br />
                <br />
                <button className="primary w-100" children={t('Sign up now')} onClick={() => navigate(`/marketplace/${slug}/enroll`)} />
            </div>
            <Description dangerouslySetInnerHTML={{ __html }} className="t-large" />
            {!!studyPlan.length && (
                <TopicList>
                    <h3>{t('Topic List')}</h3>
                    {studyPlan.map(({ title }, key) => (
                        <SessionStepList
                            key={key + title}
                            session={{
                                id: title,
                                title,
                                number: key + 1,
                                status: 'done',
                                steps: [],
                            }}
                        />
                    ))}
                </TopicList>
            )}
            <br />
        </CourseDetailsStyle>
    )
}

export const CourseDetailsStyle = styled.section`
    padding: 0.5rem 2rem;
    & > article.card {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: max-content auto min-content max-content;
        align-items: center;
        & > main {
            align-self: flex-start;
            margin: 1.1rem 0;
        }
    }
`

export const Tags = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 1em;
    align-items: center;
    img {
        height: 1.2em;
        border-radius: 4px;
    }
`

export const Label = styled.label<{ color: string }>`
    background-color: ${({ color }) => color};
    display: inline-block;
    color: white;
    font-weight: bold;
    border-radius: 6px;
    padding: 3px 10px;
    font-size: 0.85em;
`

const Description = styled.div`
    padding: 1.3em 0.8em;
`

const TopicList = styled.div`
    .topic-card {
        background-color: #0037500f;
        padding: 1em 2.3em;
        margin: 2em 0;
        border-radius: ${defaultTheme.borderRadius};
        span {
            display: inline-block;
            font-size: 1.15em;
            font-weight: bold;
            line-height: 2em;
        }
    }
`
