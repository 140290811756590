import { useTranslation } from 'react-i18next'
import DayJS from '../../helpers/DayJS'
import { Input, InputProps } from './Input'
import { ChangeEvent } from 'react'

export const BirthDate = (props: BirthDateProps) => {
    const { t } = useTranslation('signUp')
    const { value, label = t('Birthday'), ...rest } = props
    const date = typeof value === 'string' ? DayJS(value) : undefined
    return (
        <Input
            {...{
                label,
                type: 'date',
                id: 'dateBirth',
                name: 'dateBirth',
                defaultValue: date?.isValid() ? date.format('YYYY-MM-DD') : undefined,
                autoComplete: 'bday',
                ...rest,
                onChange: ({ target: { name, value } }) =>
                    props?.onChange?.({
                        target: { name, value: DayJS(value) },
                    } as unknown as ChangeEvent<HTMLInputElement>),
            }}
        />
    )
}

type BirthDateProps = Omit<InputProps, 'value'> & {
    value?: string | null
}
