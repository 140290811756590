import { Navigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { defaultTheme } from '../../styles/themes/defaultTheme'
import { useContentfulRenderOptions } from '../../hooks/useContentfulRenderOptions'
import { useContentStep } from '../../hooks/useContentStep'
import { useToken } from '../../hooks/useToken'
import { useUserCourse } from '../../hooks/queries/useUserCourses'

export const StepManager = () => {
    const { stepId } = useParams()
    const { step, status: stepStatus } = useContentStep(stepId)
    const { RenderStepContent } = useContentfulRenderOptions({ step, status: stepStatus })

    return (
        <>
            <Container>
                <RenderStepContent />
            </Container>
        </>
    )
}

export const LegacyStepRedirect = () => {
    const { t } = useTranslation('content')
    const { stepId } = useParams()
    const { _classId: courseId } = useToken()
    const { courseSlug, isLoading } = useUserCourse(courseId)

    const slugOrId = courseSlug || courseId
    const url = `/${slugOrId}/${stepId}`

    if (isLoading) return <p>{t('Redirecting to content...')}</p>

    return <Navigate to={url} />
}

const Container = styled.section`
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    margin-top: 12px;

    & > .content-board-header {
        padding-top: 1em;
        display: grid;
        grid-gap: 1em;
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: ${defaultTheme.color.offWhite};
        & > nav {
            & > button {
                min-width: 200px;
                & > svg {
                    font-size: 15px;
                }
            }
        }
        & > section {
            z-index: 2;
            box-shadow: 0px 4px 4px 0px rgba(0, 55, 80, 0.15);
            border: none;
            & > .section-card:hover {
                background: transparent !important;
            }
            & .section-card {
                column-gap: 20px;

                & > .section-card-title {
                    grid-template-columns: auto 1fr auto;
                    & > h4 {
                        font-weight: 700;
                    }
                    & > h4,
                    .section-card-subtitle {
                        font-size: 1.43rem;
                    }
                    & > .underline-hover:hover {
                        text-decoration: none;
                    }
                }
            }
            @media screen and (max-width: 768px) {
                & > .section-card {
                    padding: 1em 2em;
                    grid-template-areas: 'leftItem title items' !important;
                    grid-template-columns: [leftItem] auto [title] 1fr [items] auto !important;
                    grid-column-gap: 1em;
                    & > .section-card-title {
                        grid-template-columns: auto;
                        gap: 0.5em;
                        & > h4,
                        .section-card-subtitle {
                            font-size: 1.2rem;
                        }
                    }
                }
            }
        }
        .icon {
            font-size: 18px;
        }
        @media screen and (max-width: 768px) {
            .icon {
                font-size: 18px;
            }
        }
    }
`
