import { jwtDecode } from 'jwt-decode'

export const isEmailValid = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

export const trimEmail = (email: string) => {
    return email.trim()
}

export function extractYouTubeID(url: string) {
    const regex = /[?&]v=([^&#]*)/
    const match = regex.exec(url)
    return match ? match[1] : null
}

export const testPasswordStrength = (password: string, options: PasswordStrengthOptions = {}) => {
    const { length, lowercase = false, uppercase = false, special = false } = options
    const errors: ErrorsType[] = []

    if (length) {
        errors.push({
            type: 'length',
            message: `The password must have a minimum of ${length} characters.`,
            isValid: password.length >= length,
        })
    }
    if (lowercase) {
        errors.push({
            type: 'lowercase',
            message: 'The password must contain lowercase letters.',
            isValid: /[a-z]/.test(password),
        })
    }
    if (uppercase) {
        errors.push({
            type: 'uppercase',
            message: 'The password must contain uppercase letters.',
            isValid: /[A-Z]/.test(password),
        })
    }
    if (special) {
        errors.push({
            type: 'special',
            message: 'The password must contain special characters. ($, @, #, &, !, or .)',
            isValid: /[$@#&!.]/.test(password),
        })
    }

    return errors
}

export const decodeToken = <T>(token: string) => {
    try {
        return jwtDecode<T>(token)
    } catch (_error) {
        return null
    }
}

export const isTokenValid = (token: string) => {
    try {
        const { exp } = jwtDecode<{ exp: number }>(token)
        return exp * 1000 > Date.now()
    } catch (_error) {
        return false
    }
}

export const isTokenExpired = (token: string) => !isTokenValid(token)

interface PasswordStrengthOptions {
    length?: number
    lowercase?: boolean
    uppercase?: boolean
    special?: boolean
}

export interface ErrorsType {
    type: 'length' | 'lowercase' | 'uppercase' | 'special' | 'equalsPasswords'
    message: string
    isValid: boolean
}