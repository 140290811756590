import { Dispatch, ReactNode, SetStateAction, Suspense, memo, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Title } from '../../components/Title'
import { Icon } from '../../components/Icon'
import { useUser } from '../../hooks/queries/useUser'
import { useLanguages } from '../../hooks/useLanguages'
import { useEcosystem } from '../marketplace/hooks/useEcosystem'
import { CourseCard, CourseCardSkeleton } from '../Home/component/component/CourseCard'
import styled from 'styled-components'
import { EcosystemCourseDTO, Tag } from '../marketplace/api-marketplace'

export const Ecosystem = () => {
    const { t } = useTranslation('ecosystem')

    const { user } = useUser()
    const { userLang } = useLanguages()
    const [lang, setLang] = useState<string | undefined>(userLang.resolvedLanguage)

    const { ecosystem, allTags, isFetching } = useEcosystem({ lang })

    const [filterByTags, setFilterByTags] = useState<string[]>([])
    const filterByTitleState = useState('')
    const [filterByTitle] = filterByTitleState

    const filterByTagsFn = useCallback(
        ({ tags }: { tags: Tag[] }) =>
            !filterByTags?.length ? true : tags.some(tag => filterByTags.includes(tag.name)),
        [filterByTags]
    )
    const filterByTitleFn = useCallback(
        ({ title, description }: { title: string; description: string }) =>
            filterByTitle
                ? title.toLowerCase().includes(filterByTitle.toLowerCase()) ||
                  description.toLowerCase().includes(filterByTitle.toLowerCase())
                : true,
        [filterByTitle]
    )

    if (!user || user?.hideMarketplace) return null

    const filteredCourses = ecosystem?.courses.filter(filterByTagsFn).filter(filterByTitleFn)
    const onlyCareers = filteredCourses?.filter(
        ({ title }) =>
            title.toLowerCase().startsWith('career') || title.toLowerCase().startsWith('carrera')
    )
    const onlyCourses = filteredCourses?.filter(
        ({ title }) =>
            !(title.toLowerCase().startsWith('career') || title.toLowerCase().startsWith('carrera'))
    )

    const filteredTags = Array.from(
        filteredCourses
            ? new Set(
                  filteredCourses
                      .map(({ tags }) => tags)
                      .flat()
                      .map(({ name }) => name)
                      .sort()
              )
            : []
    )

    const LanguageSelector = memo(() => {
        return (
            <button
                className="text display-flex"
                onClick={() => setLang(prev => (!prev ? userLang.resolvedLanguage : undefined))}
                disabled={isFetching}
            >
                {!lang ? t('In any language') : t('In {{ lang }}', { lang: userLang.nativeName })}
                <Icon icon={['far', 'language']} />
            </button>
        )
    })
    LanguageSelector.displayName = 'LanguageSelector'

    return (
        <EcosystemStyles>
            <Title title={t('Ecosystem')} subTitle="" size="large">
                <SearchBar filterByTitleState={filterByTitleState} />
            </Title>
            {allTags.length > 0 && (
                <div className="ecosystems-buttons">
                    {allTags.map(label => (
                        <button
                            key={label}
                            disabled={!filteredTags.includes(label)}
                            className={filterByTags.includes(label) ? 'primary' : 'outlined'}
                            onClick={() =>
                                setFilterByTags(prev =>
                                    prev.includes(label)
                                        ? prev.filter(item => item !== label)
                                        : [...prev, label]
                                )
                            }
                        >
                            {label}
                        </button>
                    ))}
                </div>
            )}
            {!!onlyCareers?.length && (
                <>
                    <Section
                        title="Careers"
                        subtitle={{
                            whileFetching: 'We are looking for the best career for you...',
                            afterFetching: 'Find the career that best suits you.',
                        }}
                        filter={filterByTags}
                        isFetching={isFetching}
                        courses={onlyCareers}
                        titleRight={<LanguageSelector />}
                    />

                    <br />
                </>
            )}

            <Section
                title="Courses"
                subtitle={{
                    whileFetching: 'We are looking for the best courses for you...',
                    afterFetching: 'Consult our courses and personalize your learning.',
                }}
                filter={filterByTags}
                isFetching={isFetching}
                courses={onlyCourses}
                titleRight={<LanguageSelector />}
            />
        </EcosystemStyles>
    )
}

const Section = ({
    courses,
    title,
    subtitle,
    filter,
    titleRight,
    isFetching,
}: {
    courses?: EcosystemCourseDTO[]
    title: string
    subtitle: {
        whileFetching: string
        afterFetching: string
    }
    filter?: string[]
    titleRight?: ReactNode
    isFetching: boolean
}) => {
    const { t } = useTranslation('home')
    return (
        <Card>
            <Title
                title={t(title)}
                subTitle={t(isFetching ? subtitle.whileFetching : subtitle.afterFetching)}
            >
                <div className="language-selector">{titleRight}</div>
            </Title>
            {courses && !courses.length && <p>{t('There are no courses available.')}</p>}
            <Grid>
                <Suspense fallback={<Skeleton />}>
                    {isFetching ? (
                        <Skeleton />
                    ) : (
                        courses
                            ?.filter(({ tags }) =>
                                !filter?.length ? true : tags.some(tag => filter.includes(tag.name))
                            )
                            .map((network, idx) => (
                                <CourseCard key={`${idx}_${network.slug}`} {...network} />
                            ))
                    )}
                </Suspense>
            </Grid>
        </Card>
    )
}

const EcosystemStyles = styled.section`
    display: grid;
    grid-gap: 2rem;
    & > .ecosystems-buttons {
        display: flex;
        gap: 1em;
        flex-wrap: wrap;
        & > button {
            margin: 0 !important;
            @media screen and (max-width: 340px) {
                max-width: min-content;
            }
        }
    }
`

const Card = styled.div`
    display: grid;
    grid-gap: 3rem;
    .right {
        align-self: end;
    }
`

const Grid = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    @media (max-width: 768px) {
        flex-direction: column;
        padding-bottom: 3rem;
    }
    & article.card {
        width: 426px;
        height: 380px;
        display: grid;
        grid-template-rows: max-content max-content auto 1fr 45px;
        gap: 0.5em;
        box-sizing: border-box;
        @media screen and (max-width: 768px) {
            width: 100%;
            min-height: auto;
        }
        .top-tags {
            display: flex;
            justify-content: flex-end;

            & > *:not(:last-child) {
                margin-right: 10px;
            }
        }

        .title {
            margin: 0;
            font-size: 1.714rem;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.48px;
            text-transform: capitalize;
        }

        .tags {
            display: flex;
            align-items: center;
            gap: 0.5em;
            min-height: 24px;
            margin: 1em 0;
            > * {
                width: fit-content;
            }
            img {
                width: 8%;
                border-radius: 4px;
                margin: 3px 0;
            }
        }

        .description {
            font: normal normal normal 14px/22px Poppins;
            color: #18495f80;
            text-align: left;

            // wrap text in 6 lines
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 6;
            -webkit-box-orient: vertical;

            label {
                display: inline-block;
                color: white;
                font-weight: bold;
                background-color: #00a3ff;
                border-radius: 6px;
                padding: 3px 10px;
                font-size: 0.86em;

                &:not(:first-child) {
                    margin-left: 5px;
                }
            }
        }

        footer {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }

        &:hover {
            border-color: #0037504d;
        }
    }
`

const Skeleton = () => (
    <>
        <CourseCardSkeleton />
        <CourseCardSkeleton />
        <CourseCardSkeleton />
    </>
)

const SearchBar = ({
    filterByTitleState,
}: {
    filterByTitleState: [string, Dispatch<SetStateAction<string>>]
}) => {
    const { t } = useTranslation('ecosystem')
    const [isSearching, setIsSearching] = useState(false)
    const [filterByTitle, setFilterByTitle] = filterByTitleState

    return (
        <div
            className="search"
            style={{
                display: 'flex',
                alignItems: 'center',
                height: 55,
            }}
        >
            {isSearching || !!filterByTitle ? (
                <>
                    <input
                        type="text"
                        placeholder={t('Search by course')}
                        value={filterByTitle}
                        onChange={({ target: { value } }) => setFilterByTitle(value)}
                        style={{
                            marginRight: '-3em',
                            paddingRight: '3em',
                        }}
                    />
                    <button className="icon outlined" onClick={() => setIsSearching(false)}>
                        <Icon
                            icon={['far', 'times']}
                            size="lg"
                            onClick={() => setFilterByTitle('')}
                        />
                    </button>
                </>
            ) : (
                <button className="icon outlined">
                    <Icon
                        icon={['far', 'magnifying-glass']}
                        size="lg"
                        onClick={() => setIsSearching(prev => !prev)}
                    />
                </button>
            )}
        </div>
    )
}
