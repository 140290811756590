import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ErrorsType } from '../../helpers/validators'
import { ErrorMessage, preventSpaceInput, preventSpacePaste } from './Input'

export const PasswordInput = ({ label, errors, value: password, ...props }: PasswordInputProps) => {
    const { t } = useTranslation('signUp')

    const [showPassword, setShowPassword] = useState(false)

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword)
    }

    return (
        <PasswordInputContainer>
            {label && <label>{label}</label>}
            <div className="password-input-wrapper">
                <input
                    type={showPassword ? 'text' : 'password'}
                    onKeyDown={preventSpaceInput}
                    onPaste={preventSpacePaste}
                    {...props}
                />
                <button
                    type="button"
                    className="toggle-password"
                    onClick={togglePasswordVisibility}
                >
                    <FontAwesomeIcon icon={showPassword ? ['fas', 'eye-slash'] : ['fas', 'eye']} />
                </button>
            </div>
            {errors?.map((error, index) => (
                <ErrorMessage key={index} isValid={error.isValid}>
                    <FontAwesomeIcon
                        icon={['fas', error.isValid ? 'circle-check' : 'times-circle']}
                    />{' '}
                    {t(error.message)}
                </ErrorMessage>
            ))}
        </PasswordInputContainer>
    )
}

export interface PasswordInputProps
    extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    value?: string
    label?: ReactNode
    errors?: ErrorsType[]
    onBlur?: () => void
}

const PasswordInputContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;

    & > label {
        font-size: 1rem;
    }

    .password-input-wrapper {
        position: relative;
        display: flex;
        align-items: center;
    }

    .toggle-password {
        width: max-content !important;
        min-height: max-content !important;
        position: absolute;
        right: 1rem;
        background: none;
        border: none;
        cursor: pointer;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
`
