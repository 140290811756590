import { GACategory, SendGAEvent } from '../../helpers/GAEvents'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PrimaryBtnBlue } from '../../components/Buttons/PrimaryBtn'
import axios from 'axios'
import { useUserContentProgression } from '../../hooks/useUserContentProgression'
import { useToken } from '../../hooks/useToken'

const ContentPage = () => {
    const { _classId } = useToken()
    const { contentDay } = useUserContentProgression(_classId)

    const getContentDescriptionLabel = () => {
        if (contentDay?.contentDist.contentDescription !== '' && contentDay?.contentDist.contentDescription !== null) {
            return `Hoy verás: ${contentDay?.contentDist.contentDescription}`
        } else {
            return `Este es el principio de tu camino a la industria digital.`
        }
    }

    const moodleAutoLogin = async () => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_WIDGET_API}/v1/moodle/autologin`)
            window.open(res.data.loginUrl)
            SendGAEvent(GACategory.CONTENT, `Moodle Login`, `From: icon`)
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (error) {
            console.log('Error login on Moodle')
            SendGAEvent(GACategory.ERROR, 'Error login on Moodle')
        }
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
            }}
        >
            <FontAwesomeIcon icon={['fal', 'folder']} style={{ fontSize: 78, color: '#00A3FF' }} />
            <h4 style={{ margin: '40px 0 8px' }}>CONTENIDO DEL ENCUENTRO</h4>
            <h1 style={{ margin: '0 0 30px' }}>{getContentDescriptionLabel()}</h1>
            <PrimaryBtnBlue onClick={moodleAutoLogin}>Ir al contenido</PrimaryBtnBlue>
        </div>
    )
}

export default ContentPage
