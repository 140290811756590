import Axios from 'axios'

const { REACT_APP_API_PRESENT } = process.env

if (!REACT_APP_API_PRESENT)
    console.error('REACT_APP_API-PRESENT to be declared on your system environments variables')

const baseURL = `${REACT_APP_API_PRESENT}`

export const ApiPresent = Axios.create({
    baseURL,
})

export const setUserPresent = (courseId: string) =>
    ApiPresent.post<API<UserPresentDTO>>('/v1/presents', { courseId })
        .then(({ data }) => {
            if (!data.data.canSetPresent)
                sessionStorage.setItem(`require-present-${courseId}`, 'true')
            else sessionStorage.removeItem(`require-present-${courseId}`)
            return data.data
        })
        .catch(error => {
            // Handle 429 error
            if (error.response.status === 429) {
                return { canSetPresent: false }
            }
            throw error
        })

interface API<TData> {
    code: number
    message: string
    data: TData
}

type UserPresentDTO =
    | { canSetPresent: false }
    | {
          canSetPresent: true
          id: string
          present: boolean
          status: 'present' | 'absent'
          expiration: string // Date
          open: string // Date
          createdAt: string // Date
      }
