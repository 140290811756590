import { useTranslation } from 'react-i18next'
import { Select } from './FormElements/Select'
import { useLanguages } from '../hooks/useLanguages'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { useEffect, useRef, useState } from 'react'
import { defaultTheme } from '../styles/themes/defaultTheme'

export const LanguageSelector = ({ labels = 'names', onSelect }: LanguageSelectorProps) => {
    const { i18n } = useTranslation()
    const { langs } = useLanguages()
    const [isOpen, setIsOpen] = useState(false)
    const valueRef = useRef<HTMLDivElement>(null)

    const handleChange = (language: string) => {
        i18n.changeLanguage(language)
        onSelect?.(language)
    }

    const handleClickOutside = (event: MouseEvent) => {
        if (valueRef.current && !valueRef.current.contains(event.target as Node)) {
            setIsOpen(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    return (
        <LanguageSelectorStyles ref={valueRef}>
            {isOpen && (
                <div className="options">
                    {Object.entries(langs ?? {}).map(([key, value]) => (
                        <div
                            key={key}
                            className={`option ${key === i18n.language ? 'active' : ''}`}
                            onClick={() => handleChange(key)}
                        >
                            {labels === 'keys' ? key : value.nativeName}
                        </div>
                    ))}
                </div>
            )}
            <button className="language-switch" onClick={() => setIsOpen(!isOpen)}>
                <FontAwesomeIcon className="language-icon" icon={['far', 'language']} fixedWidth />
                <FontAwesomeIcon
                    icon={['fas', 'chevron-down']}
                    flip={isOpen ? 'vertical' : 'horizontal'}
                    fixedWidth
                />
            </button>
        </LanguageSelectorStyles>
    )
}

type LanguageSelectorProps = {
    labels?: 'names' | 'keys'
    onSelect?: (language: string) => void
} & React.ComponentProps<typeof Select>

const LanguageSelectorStyles = styled.div`
    & > .options {
        margin: 0.5rem 2rem;
        padding: 1rem;
        position: relative;
        z-index: 2147483004; // Es un numero grande para que quede delante del botón de intercom que trae el z-index 2147483003
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 0.5rem;
        background-color: ${defaultTheme.color.white};
        border: 1px solid ${defaultTheme.color.lightGray};
        border-radius: 20px;
        & > .option {
            cursor: pointer;
            &.active {
                color: ${defaultTheme.color.electricBlue};
                font-weight: bold;
            }
            &:hover {
                color: ${defaultTheme.color.electricBlue};
            }
        }
    }
    & > .language-switch {
        width: 100%;
        padding: 0 2rem 1rem 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 1rem;
        background: none;
        border: none;
        cursor: pointer;
        .language-icon {
            color: ${defaultTheme.color.primary};
            width: 30px;
            height: 30px;
        }
    }
    @media screen and (max-width: 768px) {
        & > .options {
            margin-right: 1rem;
        }
    }
`
