import DayJS from 'dayjs'
import duration from 'dayjs/plugin/duration'
import es from 'dayjs/locale/es'
import i18n from '../i18n'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isToday from 'dayjs/plugin/isToday'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import pt from 'dayjs/locale/pt-br'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

DayJS.extend(isSameOrAfter)
DayJS.extend(isSameOrBefore)
DayJS.extend(isBetween)
DayJS.extend(duration)
DayJS.extend(timezone)
DayJS.extend(utc)
DayJS.extend(relativeTime)
DayJS.extend(localizedFormat)
DayJS.extend(isToday)
DayJS.locale(pt)
DayJS.locale(es)

export const humanize = (date: DayJS.Dayjs) =>
    DayJS.duration(date.diff(DayJS())).locale(i18n.resolvedLanguage ?? 'en').humanize(true)

export default DayJS
