import { Video } from '../Video'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { NavLink, useParams } from 'react-router-dom'

export const MyTeamOnAsyncCourses = () => {
    const { slugOrId } = useParams()
    const { t } = useTranslation('widget')

    return (
        <AsyncStyle className="column-center">
            <Video
                SpanishUrl="https://youtube.com/shorts/07RhRp2ZOcw"
                EnglishUrl="https://youtube.com/shorts/ZSP5DXkyvf0"
                width={203}
                style={{
                    borderRadius: 6,
                    overflow: 'hidden',
                    maxHeight: '55vh',
                }}
                controls={false}
            />

            <NavLink to={`/${slugOrId}?step=first`} className=" button primary w-100 only-desktop">
                {t('Start now')}
            </NavLink>
        </AsyncStyle>
    )
}

const AsyncStyle = styled.div`
    width: 240px;
    height: 100%;
    padding: 16px;
    gap: 5em;
    text-align: center;
    @media screen and (max-width: 768px) {
        width: 100%;
        height: 100%;
        & > * {
            border-radius: 0 !important;
            width: 100% !important;
            height: 100% !important;
        }
    }
`
