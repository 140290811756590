import { getCohorts } from '../api-marketplace'
import { useQuery } from '@tanstack/react-query'

export const useEcosystemCohorts = ({ slug, courseId }: { slug?: string; courseId?: string }) => {
    const slugOrId = slug ?? courseId
    const queryKey = ['Ecosystem', slugOrId, 'Cohorts']
    const { data: cohorts, ...rest } = useQuery({
        queryKey,
        queryFn: () => getCohorts(`${courseId}`),
        enabled: !!courseId,
    })

    const getCohortById = (cohortId?: string) => cohorts?.find(({ id }) => id === cohortId) ?? null

    return { cohorts, getCohortById, ...rest }
}
