import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { EnrollProps } from './Enroll'
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { defaultTheme } from '../../../styles/themes/defaultTheme'
import { ReactNode } from 'react'
import { useParams } from 'react-router-dom'
import { useFrequency } from '../hooks/useFrequency'
import DayJS from '../../../helpers/DayJS'
import { arrayToList, capitalize } from '../../../helpers/formatters'
import { generateTime } from '../../../helpers/dateFormatters'
import { useEcosystemCohorts } from '../hooks/useEcosystemCohorts'
import { useEcosystemCourse } from '../hooks/useEcosystemCourse'
import { ButtonsBlock } from './ButtonsBlock'

export const ConfirmView = ({ choices, ...rest }: EnrollProps) => {
    const { t } = useTranslation('enroll')
    const { slug } = useParams()
    const { course } = useEcosystemCourse(slug)
    const { getFrequencyByCourseId, getCourseById } = useFrequency(slug)
    const [{ courseId, cohortId }] = choices

    const { getCohortById } = useEcosystemCohorts({ slug, courseId })

    const frequency = getFrequencyByCourseId(courseId)
    const courseFrequency = getCourseById(courseId)
    const cohort = getCohortById(cohortId)

    return (
        <>
            <div>
                <h1 className="title">{t(`Let's confirm your information.`)}</h1>
                <p className="light">
                    {t(
                        'Take a minute and make sure the information displayed on this screen is correct. This is what we will use for your registration.'
                    )}
                </p>
                <br />
                <Container className="personal-info">
                    <Section
                        icon={['fal', 'bullseye-arrow']}
                        title={t('Professional objective')}
                        info={<p className="capitalize">{t(course?.title ?? '')}</p>}
                    />

                    {!!frequency && courseFrequency && (
                        <Section
                            icon={['fal', 'gauge']}
                            title={t('Rhythm')}
                            info={
                                <>
                                    <p>{t(frequency.title)}</p>
                                    <p>
                                        {capitalize(
                                            arrayToList(
                                                frequency.days.map(day => DayJS().day(day).format('dddd')),
                                                ` ${t('and')} `
                                            )
                                        )}
                                    </p>
                                    <p>
                                        {t(
                                            'From {{ start }} to {{ end }} hs',
                                            generateTime(
                                                courseFrequency.from,
                                                courseFrequency.to,
                                                courseFrequency.fromMinutes,
                                                courseFrequency.toMinutes
                                            )
                                        )}
                                    </p>
                                </>
                            }
                        />
                    )}

                    {!!cohort && (
                        <Section
                            icon={['fal', 'calendar']}
                            title={t('Start date')}
                            info={
                                <>
                                    <p>{capitalize(DayJS(cohort.date).format('LLLL'))}</p>
                                </>
                            }
                        />
                    )}
                    {cohortId === 'none' && (
                        <Section
                            icon={['fal', 'calendar']}
                            title={t('Start date')}
                            info={<p>{t('Join the list, waiting for new available dates.')}</p>}
                        />
                    )}
                </Container>
            </div>
            <ButtonsBlock {...{ ...rest, step: 'confirm' }} />
        </>
    )
}

const Section = ({ icon, title, info }: { icon: FontAwesomeIconProps['icon']; title: string; info: ReactNode }) => (
    <section>
        <div className="icon">
            <FontAwesomeIcon icon={icon} style={{ marginRight: '0' }} />
        </div>
        <div className="info">
            <h2>{title}</h2>
            {info}
        </div>
    </section>
)

const Container = styled.div`
    border-radius: ${defaultTheme.borderRadius};
    overflow: hidden;
    border: 1px solid ${defaultTheme.color.lightBordes};
    padding: 24px 0;

    & > section {
        min-height: fit-content !important;
        display: grid;
        grid-template-columns: [icon] 26px [text] 1fr;
        gap: 24px;
        padding: 0px 24px 24px;

        > * {
            overflow: hidden;
            padding-top: 24px;
        }

        & * {
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .icon {
            font-size: 26px;
        }

        .info {
            border-top: 1px solid ${defaultTheme.color.lightBordes};

            &:first-child {
                border-top: 0;
            }

            h2 {
                margin: 0;
                font-size: 14px;
            }
            p {
                margin: 0;
                color: ${defaultTheme.color.blueSoft};
            }
        }

        &:first-child {
            > * {
                padding-top: 0;
            }

            .info {
                border-top: 0;
            }
        }
    }
`
