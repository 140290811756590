import DayJS from './DayJS'
import { setUserPresent } from '../infrastructure/api-present'
import { setUserPresentOld } from '../infrastructure/api-widget'
import { UserCoursesDTO } from '../interfaces/api-activation'

export const setPresentStatus = (course: UserCoursesDTO) => {
    const { nextClass, status } = course
    if (status !== 'studying')
        return console.info(`User is not studying, don't require to set present.`)
    if (!nextClass?.open || !nextClass?.end)
        return console.warn('Course next class info is not available.')

    const isInRange = DayJS().isBetween(DayJS(nextClass.open), DayJS(nextClass.end))

    if (isInRange) {
        handlePresent(course.courseId)
        console.info('User is in class range, setting present.')
    } else setPresentTimer(course)
}

const setPresentTimer = ({ nextClass, courseId }: UserCoursesDTO) => {
    if (!nextClass) return
    const openTime = DayJS(nextClass.open)
    const fromNow = openTime.diff(DayJS(), 'milliseconds')

    if (fromNow < 0) return
    console.info(
        `A timer was set for the user to set present at ${openTime
            .locale('en')
            .format()}, ${openTime.locale('en').fromNow()}`
    )
    return setTimeout(() => handlePresent(courseId), fromNow)
}

export const handlePresent = (courseId: string) => {
    // Canary locations
    const canaryLocation = [
        'http://localhost:8000',
        'https://develop.app.egg.live',
        'https://staging.app.egg.live',
        'https://demo.app.egg.live',
    ]

    // Canary courses
    const canaryCourseIds = [
        '6537e1fe611e3ffae5dbc54d', // Tech Team
        '667d7f321224c88f1f853470', // Team integration
        '6663680f8b643583a8af384d', // Culture & Learning
        '646e2784ab8b1459d25dbdf0', // Programación web desde cero
        '66e47f5c9d70a2c6293249e2', // Sistemática de ventas 2 pf-13
        '66e47f359d70a2637c3249e0', // Sistemática de ventas 2 pf-15
        '66e47f0e9d70a213bd3249de', // Sistemática de ventas 2 pei-13
        '66e47f029d70a26e663249dc', // Sistemática de ventas 2 pei-15
    ]

    const useNewPresent =
        canaryLocation.includes(window?.location.origin) || canaryCourseIds.includes(courseId)

    return useNewPresent ? setUserPresent(courseId) : setUserPresentOld(courseId)
}
