import styled from 'styled-components'
import { BLOCKS, INLINES, Block, Inline } from '@contentful/rich-text-types'
import { useNavigate } from 'react-router-dom'
import { extractYouTubeID } from '../helpers/validators'
import { defaultTheme } from '../styles/themes/defaultTheme'
import { Icon } from '../components/Icon'
import { ReactNode } from 'react'
import { documentToReactComponents, Options } from '@contentful/rich-text-react-renderer'
import { CodeBlock } from '../components/CodeBlock'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import ReactPlayer from 'react-player/lazy'
import { useToken } from './useToken'
import { useUser } from './queries/useUser'
import { ContentStepDTO } from '../infrastructure/api-widget'
import { useCourseStudyPlan } from './useCourseStudyPlan'
import { SkeletonTextLine } from '../components/Skeletons'

export const useContentfulRenderOptions = ({ step, status }: { step?: ContentStepDTO; status?: 'error' | 'success' | 'pending' }) => {
    const { user } = useUser()
    const { _classId, _profileId } = useToken()
    const { findSessionByStepId } = useCourseStudyPlan(_classId)
    const navigate = useNavigate()

    const session = findSessionByStepId(step?._id)
    const sessionId = session?.id

    const options: Options = {
        renderNode: {
            [BLOCKS.HEADING_1]: (_node: Node, children: ReactNode) => <h1 children={children} />,
            [BLOCKS.HEADING_2]: (_node: Node, children: ReactNode) => <h2 children={children} />,
            [BLOCKS.HEADING_3]: (_node: Node, children: ReactNode) => <h3 children={children} />,
            [BLOCKS.HEADING_4]: (_node: Node, children: ReactNode) => <h4 children={children} />,
            [BLOCKS.HEADING_5]: (_node: Node, children: ReactNode) => <h5 children={children} />,
            [BLOCKS.HEADING_6]: (_node: Node, children: ReactNode) => <h6 children={children} />,

            [BLOCKS.HR]: () => <hr />,

            [BLOCKS.PARAGRAPH]: (node: any, children: ReactNode) => {
                const type: string | undefined = node?.content?.[0]?.marks?.[0]?.type
                return type === 'code' ? <CodeBlock children={children} /> : <p children={children} />
            },

            [BLOCKS.QUOTE]: (_: Node, children: ReactNode) => <Blockquote children={children} />,

            [INLINES.HYPERLINK]: (node: any, children: ReactNode) => {
                const uri = node?.data?.uri
                const value = node?.content[0]?.value
                if (!uri || !value) return children
                const isForm = uri?.includes('typeform.com')
                const isNPS = uri?.includes('form_type=nps')

                const href = isForm
                    ? `${uri}&profile_id=${_profileId}&step_id=${step?._id}&module_id=unknown&section_id=${sessionId}&class_id=${_classId}&email=${user?.email}`
                    : uri

                if (isForm) return <ExternalLink href={href} icon={['far', !isNPS ? 'ballot-check' : 'badge-check']} value={value} />
                if (uri?.includes('vimeo.com') || uri?.includes('youtube.com/watch'))
                    return (
                        <ReactPlayer
                            url={uri}
                            controls={true}
                            width={'auto'}
                            height={'auto'}
                            style={{
                                margin: '1em 0',
                                aspectRatio: '16 / 9',
                                borderRadius: '6px',
                                overflow: 'hidden',
                            }}
                        />
                    )

                if (uri?.includes('youtu.be')) return <ExternalLink href={uri} icon={['far', 'film']} value={value} />

                if (uri?.includes('youtube.com/watch'))
                    return <Iframe src={`https://www.youtube.com/embed/${extractYouTubeID(uri)}`} title={value} allow="fullscreen;" />

                return <ExternalLink href={href} value={value} />
            },

            [BLOCKS.TABLE]: (_: Node, children: ReactNode) => <Table children={children} />,

            [BLOCKS.EMBEDDED_ASSET]: (node: Node) => {
                const contentType = node?.data?.target?.fields?.file?.contentType
                const href = node?.data?.target?.fields?.file?.url
                const title = node?.data?.target?.fields?.title
                if (!contentType || !href) return null

                if (contentType.includes('video/'))
                    return (
                        <video
                            controls
                            style={{
                                borderRadius: '6px',
                                margin: '.5em 0',
                            }}
                        >
                            <source src={`https:${href}`} type={contentType} />
                            Your browser does not support the video tag. Please use Google Chrome.
                        </video>
                    )
                if (contentType.includes('image/')) return <img src={`https:${href}`} alt={title} />
                if (contentType.includes('application/')) return <ExternalLink href={href} icon={['far', 'file']} value={title} />
            },

            [INLINES.EMBEDDED_ENTRY]: (node: Node, _children: ReactNode) => (
                <button
                    className="primary"
                    children={node?.data?.target?.fields?.title}
                    onClick={() => navigate(`/content/step/${node?.data?.target?.sys?.id}`)}
                />
            ),
        },
    }

    const RenderStepContent = () => (
        <StyledContentWrapper>
            {status === 'pending' && <StepSkeleton />}

            {step && (
                <>
                    <h2 className="no-margins">{step?.title}</h2>
                    {documentToReactComponents(step.description, options)}
                    {step.suggestedExercise && documentToReactComponents(step.suggestedExercise, options)}
                </>
            )}
        </StyledContentWrapper>
    )

    return {
        options,
        RenderStepContent,
    }
}

const ExternalLink = ({ href, value, icon }: { href: string; value: string; icon?: IconProp }) => {
    return (
        <Link href={href} target="_blank" rel="noopener noreferrer" className="button outlined">
            {icon && <Icon icon={icon} size="xl" />}
            <span>{value}</span>
        </Link>
    )
}

const StepSkeleton = () => (
    <>
        <h2 className="skeleton no-margins" children=" " style={{ height: 42 }} />
        <SkeletonTextLine repeat={3} />
        <div
            className="skeleton"
            children=" "
            style={{
                height: 500,
            }}
        />
    </>
)

type Node = Block | Inline

const Link = styled.a`
    text-decoration: underline !important;
    color: #00a3ff !important;
    display: inline !important;
    &:hover {
        color: #105df9 !important;
    }
    & > svg {
        margin: 0 0.2em 0 0 !important;
        height: 18px;
    }
`

const Iframe = styled.iframe`
    width: 100%;
    min-height: 24em;
    border: 0;
    border-radius: 6px;
    width: 100%;
    width: '--webkit-fill-available';
    border: 0;
    border-radius: 6px;
`

const Blockquote = styled.blockquote`
    margin: 1em 0;
    border-radius: 6px;
    padding: 2em;
    background-color: ${defaultTheme.color.lightGray};
`

const Table = styled.table`
    width: 100%;
    border-radius: 6px;
    border-collapse: separate;

    th:first-child {
        border-top-left-radius: 6px;
    }

    th:last-child {
        border-top-right-radius: 6px;
    }

    tr:nth-child(odd) {
        background-color: ${defaultTheme.color.offWhite};
    }

    th,
    td {
        padding: 0.5em;
        border: 1px solid ${defaultTheme.color.lightGray};
    }

    th {
        color: white;
        background-color: ${defaultTheme.color.primary};
    }

    p {
        margin: 0.5em 0;
        text-align: center;
        all: unset;
    }
`

const StyledContentWrapper = styled.div`
    box-sizing: border-box;
    margin-bottom: 5em;
    padding: 3em 2em;
    border-radius: 16px;
    background: ${props => props.theme.color.white};
    color: ${defaultTheme.color.primary};
    & > * {
        max-width: 100% !important;
    }

    p {
        line-height: initial;
    }

    a {
        text-decoration: underline;
    }

    & > table {
        font-size: 0.9em;
    }
    & code {
        white-space: pre-wrap;
    }

    // Mobile styles
    @media screen and (max-width: 768px) {
        border: 0;
        padding: 2em;
        h2 {
            font-size: 1.5em;
        }
    }
`
