import { useQuery } from '@tanstack/react-query'
import { getRankingCourses } from '../../../infrastructure/api-ranking'
import { useToken } from '../../../hooks/useToken'

export const useRankingCourses = () => {
    const queryKey = ['Ranking', 'Courses']
    const { _profileId } = useToken()

    const { data: rankingCourses, ...rest } = useQuery({
        queryKey,
        queryFn: () => getRankingCourses(),
        enabled: !!_profileId,
        retry: 2,
    })

    return {
        rankingCourses,
        ...rest,
    }
}
