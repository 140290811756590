import styled from 'styled-components'
import DynamicCard from './DynamicCard'
import { DeckType } from '../../interfaces/constants'
import { GACategory, SendGAEvent, setCorrectGAEventName } from '../../helpers/GAEvents'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { CardType } from '../../interfaces/user'
import { useUserContentProgression } from '../../hooks/useUserContentProgression'

import { useActiveCourse } from '../../pages/course/hooks/useActiveCourse'
import { useMemo } from 'react'

const DynamicDeck = () => {
    return (
        <Deck>
            <CourseSyncCards />
        </Deck>
    )
}
export default DynamicDeck

const CourseSyncCards = () => {
    const navigate = useNavigate()
    const { activeCourse } = useActiveCourse()
    const { contentDay } = useUserContentProgression(activeCourse?._courseId)

    const cardsContent: CardType[] = useMemo(
        () => [
            {
                iconPrefix: 'fal',
                icon: 'egg',
                title: 'Antes de ingresar a tu encuentro',
                desc: 'Conoce la metodología y cómo la cooperación es clave para el aprendizaje.',
                slug: DeckType.Methodology,
                showCard: true,
                onClick: () => {
                    SendGAEvent(GACategory.SHORTCUT, `${setCorrectGAEventName(DeckType.Methodology)}Clicked`)
                    navigate('/content/methodology')
                },
            },
            {
                iconPrefix: 'fal',
                icon: 'circle-question',
                title: 'Para ingresar a tu encuentro',
                desc: 'Te contamos cómo ingresar y acceder a tu mesa durante el encuentro.',
                showCard: !contentDay?.enableDiscord,
                slug: DeckType.Faq,
                onClick: () => {
                    SendGAEvent(GACategory.SHORTCUT, `${setCorrectGAEventName(DeckType.Faq)}Clicked`)
                    navigate('/content/faq')
                },
            },
            {
                iconPrefix: 'fab',
                icon: 'discord',
                title: 'Comunidad Egg',
                desc: 'Encuéntrate con los canales de tu curso y la red global de Egg para seguir aprendiendo.',
                slug: DeckType.Discord,
                showCard: contentDay?.enableDiscord,
                onClick: () => {
                    SendGAEvent(GACategory.SHORTCUT, `${setCorrectGAEventName(DeckType.Discord)}Clicked`)
                    onDiscordClick()
                },
            },
        ],
        [contentDay?.enableDiscord, navigate]
    )

    if (activeCourse?.asyncCourse) return null

    return (
        <section className="card-container">
            {cardsContent
                .filter(card => card.showCard === true)
                .map(page => (
                    <DynamicCard
                        key={page.slug}
                        icon={[page.iconPrefix, page.icon]}
                        title={page.title}
                        desc={page.desc}
                        slug={page.slug}
                        onActionClick={page.onClick}
                    />
                ))}
        </section>
    )
}

export const onDiscordClick = async () => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_WIDGET_API}/v1/discord`)
        if (res && res.data.authUrl !== null) {
            window.open(res.data.authUrl)
        }
        SendGAEvent(GACategory.DISCORD, `DiscordClicked`)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (_error) {
        SendGAEvent(GACategory.ERROR, `Error opening discord link`)
    }
}

const Deck = styled.div`
    margin-top: auto;
    padding-bottom: 3em;

    .card-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        gap: 0px 20px;
        margin-top: 20px;

        @media screen and (max-width: 768px) {
            grid-template-columns: repeat(1, 1fr);
            gap: 20px 0px;
        }
    }
`
