import styles from './ReportFeedbackButton.module.css'
import { Icon } from '../Icon'
import { isSentryEnable, sendUserFeedback, type SentryUserData } from '../../lib/sentry'
import { useTranslation } from 'react-i18next'
import { useUser } from '../../hooks/queries/useUser'

export const ReportFeedbackButton = () => {
    const { user } = useUser()
    const { t } = useTranslation('App')
    if (!isSentryEnable) return null

    const userData: SentryUserData = {
        id: user?.id,
        email: user?.email,
        username: user?.displayNameOrName,
    }

    return (
        <div className={styles.container}>
            <button onClick={() => sendUserFeedback(userData)} data-tooltip={t('Report a bug')} data-tooltip-position="left">
                <Icon icon={['far', 'bug']} />
            </button>
        </div>
    )
}
