import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { AvailableLanguages } from '../i18n'

export const useLanguages = () => {
    const { i18n } = useTranslation()
    const [langs, setLangs] = useState<AvailableLanguages>()

    // Getting available languages from service
    useEffect(() => {
        if (!i18n) return

        i18n.services.backendConnector.backend.getLanguages(
            (err: Error, ret: AvailableLanguages) => {
                if (err) {
                    // Manejo del error
                    console.error('Error fetching languages:', err)
                    return
                }
                setLangs(ret)
            }
        )
    }, [i18n])

    const { resolvedLanguage='en', language } = i18n
    const userLang = { ...langs?.[resolvedLanguage], language, resolvedLanguage }

    return { langs, userLang }
}
