import { useQuery } from '@tanstack/react-query'
import { googleCalendarLink } from '../infrastructure/api-signup'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useToken } from './useToken'

export const useGoogle = (profileId?: string) => {
    const { _id } = useToken()
    const { executeRecaptcha } = useGoogleReCaptcha()

    const queryKey = ['User', _id, 'Calendar Link', profileId]

    const { data } = useQuery({
        queryKey,
        queryFn: async () => {
            const captcha_token = (await executeRecaptcha?.('createUser')) ?? ''
            return googleCalendarLink(`${profileId}`, captcha_token)
        },
        enabled: !!profileId,
    })
    return { addToCalendarUrl: data?.googleCalendarLink }
}
