import { getUserContentDay } from '../infrastructure/api-widget'
import { useQuery } from '@tanstack/react-query'

export const useUserContentProgression = (courseId?: string) => {
    const queryKey = ['Study Plan', courseId, 'Progression']

    const { data: contentDay, ...rest } = useQuery({
        queryKey,
        queryFn: () => getUserContentDay(`${courseId}`),
        enabled: !!courseId,
    })

    const userSession = {
        userSessionNumber: contentDay?.contentDist.contentDay ?? undefined,
        description: contentDay?.contentDist.contentDescription ?? undefined,
        sessionId: contentDay?.contentDist.section ?? undefined,
        moduleId: contentDay?.contentDist.module ?? undefined,
    }

    return { userSession, contentDay, ...rest }
}
