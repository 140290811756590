import { useState } from 'react'
import { SessionStepList, ContentSkeleton } from '../../components/Content/SessionStepList'
import { useUserCourse } from '../../hooks/queries/useUserCourses'
import { useCourseStudyPlan } from '../../hooks/useCourseStudyPlan'
import { useNavigate } from 'react-router-dom'

export const CourseContentList = ({ slugOrId }: { slugOrId?: string }) => {
    const navigate = useNavigate()
    const { courseId, status: courseStatus } = useUserCourse(slugOrId)
    const { plan, status: planStatus } = useCourseStudyPlan(courseId)
    const [openSession, setOpenSession] = useState<string>()

    if (courseStatus === 'pending' || planStatus === 'pending') return <ContentSkeleton />

    return (
        <>
            {plan?.sessions?.map(session => (
                <SessionStepList
                    key={session.number + session.id}
                    session={session}
                    isFolderOpen={!!openSession}
                    setIsOpen={setOpenSession}
                    onTitleClick={() => navigate(`/${slugOrId}/${session.steps[0].id}`)}
                />
            ))}
        </>
    )
}
