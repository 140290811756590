import { decodeToken } from '../helpers/validators'
import { ApiWidget } from '../infrastructure/api-widget'
import { TokenPayload } from '../interfaces/api-activation'

export const useToken = () => {
    const token = ApiWidget.defaults.headers.common.Authorization?.toString()
    let payload: TokenPayload | null = null
    try {
        if (token) payload = decodeToken<TokenPayload>(token)
    } catch (error) {
        console.error(error)
    }
    return { ...payload, token, isLogged: !!payload?._id }
}

export type TokenDTO = Partial<ReturnType<typeof useToken>>
