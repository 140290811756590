import './styles/global.scss'
import './styles/redesign.scss'
import './library.js'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { AppRoutes } from './router/AppRoutes'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import ReactGA from 'react-ga4'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ThemeProvider } from 'styled-components'
import UIContextProvider from './context/UIContext'
import { defaultTheme } from './styles/themes/defaultTheme'
import { persistUrlParameters } from './helpers/urls'
import { reCaptchaOptions } from './infrastructure/reCaptcha'
import { Suspense, useEffect } from 'react'
import LoadingPage from './pages/static/LoadingPage'
import { SocketProvider } from './context/SocketContext'

const { REACT_APP_GOOGLE_ANALYTICS } = process.env

const App = () => {
    useEffect(() => {
        persistUrlParameters()

        // Boot analytics
        if (REACT_APP_GOOGLE_ANALYTICS) ReactGA.initialize(REACT_APP_GOOGLE_ANALYTICS)
        else
            console.warn(
                'Google Analytics is not available in this site, please set GA_ID in environment variables'
            )
    }, [])

    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                staleTime: 1000 * 60 * 60 * 5,
                retry: 2,
                refetchOnWindowFocus: process.env.NODE_ENV === 'production',
            },
        },
    })

    return (
        <Suspense fallback={<LoadingPage />}>
            <QueryClientProvider client={queryClient}>
                <GoogleReCaptchaProvider {...reCaptchaOptions}>
                    <ThemeProvider theme={defaultTheme}>
                        <UIContextProvider>
                            <SocketProvider>
                                <AppRoutes />
                            </SocketProvider>
                        </UIContextProvider>
                    </ThemeProvider>
                    <ReactQueryDevtools initialIsOpen={false} />
                </GoogleReCaptchaProvider>
            </QueryClientProvider>
        </Suspense>
    )
}

export default App
