// Light icons
import {
    faArrowLeft as falArrowLeft,
    faArrowRight as falArrowRight,
    faArrowRight,
    faArrowRightArrowLeft as falArrowRightArrowLeft,
    faArrowRightFromBracket as falArrowRightFromBracket,
    faBars as falBars,
    faBell as falBell,
    faBolt as falBolt,
    faBrowser as falBrowser,
    faBullseyeArrow as falBullseyeArrow,
    faCalendar as falCalendar,
    faCamera as falCamera,
    faCameraMovie as falCameraMovie,
    faCartShopping as falCartShopping,
    faChartNetwork as falChartNetwork,
    faCheck as falCheck,
    faCheckCircle,
    faCheckSquare,
    faChevronLeft as falChevronLeft,
    faChevronRight as falChevronRight,
    faCircle as falCircle,
    faCircleArrowDown as falCircleArrowDown,
    faCircleCheck as falCircleCheck,
    faCircleCheck as fatCircleCheck,
    faCircleInfo as falCircleInfo,
    faCircleLocationArrow as falCircleLocationArrow,
    faCircleNodes as falCircleNodes,
    faCircleNotch,
    faCirclePlay as falCirclePlay,
    faCircleQuestion as falCircleQuestion,
    faCircleQuestion,
    faClipboard as falClipboard,
    faClock as falClock,
    faClock,
    faCode as falCode,
    faCoffee,
    faCoinBlank as falCoinBlank,
    faComments as falComments,
    faCopy as falCopy,
    faCube as falCube,
    faDatabase as falDatabase,
    faDownload as falDownload,
    faDrawCircle as falDrawCircle,
    faEgg as falEgg,
    faFileCertificate as falFileCertificate,
    faFilePdf as falFilePdf,
    faFolder as falFolder,
    faFutbol as falFutbol,
    faGamepadModern as falGamepadModern,
    faGauge as falGauge,
    faGaugeMax as falGaugeMax,
    faGraduationCap as falGraduationCap,
    faGrid2 as falGrid2,
    faHandPaper,
    faHeart as falHeart,
    faHome as falHome,
    faInfoCircle as falInfoCircle,
    faLinkSimple as falLinkSimple,
    faLinkSimple,
    faList as falList,
    faListUl,
    faMailbox as falMailbox,
    faMailbox,
    faMedal as falMedal,
    faMusic as falMusic,
    faNote as falNote,
    faPlane as falPlane,
    faPlayCircle as falPlayCircle,
    faScreenUsers as falScreenUsers,
    faScrewdriverWrench as falScrewdriverWrench,
    faSearch as falSearch,
    faShoePrints as falShoePrints,
    faShoppingBag as falShoppingBag,
    faShoppingCart as falShoppingCart,
    faSpinner,
    faStreetView as falStreetView,
    faThumbsDown as falThumbsDown,
    faTimes as falTimes,
    faTimesCircle,
    faTreePalm as falTreePalm,
    faTrophy as falTrophy,
    faTvRetro as falTvRetro,
    faUser as falUser,
    faUserCheck as falUserCheck,
    faUserCircle,
    faUserClock as falUserClock,
    faUserGroup as falUserGroup,
    faUserHeadset as falUserHeadset,
    faUsers as falUsers,
    faUserSlash as falUserSlash,
    faVideo as falVideo,
} from '@fortawesome/pro-light-svg-icons'
// Solid icons
import {
    faArrowLeft as fasArrowLeft,
    faArrowRight as fasArrowRight,
    faArrowRightFromBracket as fasArrowRightFromBracket,
    faBell,
    faBolt as fasBolt,
    faBug as fasBug,
    faCalendarCheck as fasCalendarCheck,
    faCalendarCirclePlus as fasCalendarCirclePlus,
    faCalendarPlus as fasCalendarPlus,
    faCamera as fasCamera,
    faChartNetwork as fasChartNetwork,
    faCheck as fasCheck,
    faCheckCircle as fasCheckCircle,
    faChevronLeft as fasChevronLeft,
    faChevronRight as fasChevronRight,
    faCircle as fasCircle,
    faCircleArrowRight as fasCircleArrowRight,
    faCircleCheck as fasCircleCheck,
    faCircleExclamation as fasCircleExclamation,
    faCircleNodes as fasCircleNodes,
    faCircleQuestion as fasCircleQuestion,
    faCircleVideo as fasCircleVideo,
    faCode as fasCode,
    faCoinVertical as fasCoinVertical,
    faComments as fasComments,
    faDownload as fasDownload,
    faDrawCircle as fasDrawCircle,
    faEgg as fasEgg,
    faFileCertificate as fasFileCertificate,
    faFolder as fasFolder,
    faFolderOpen as fasFolderOpen,
    faGraduationCap as fasGraduationCap,
    faHeart as fasHeart,
    faHome as fasHome,
    faInfoCircle as fasInfoCircle,
    faLanguage as fasLanguage,
    faLinkSimple as fasLinkSimple,
    faList as fasList,
    faLock as fasLock,
    faLockOpen as fasLockOpen,
    faMapPin as fasMapPin,
    faMedal,
    faMessageQuestion as fasMessageQuestion,
    faObjectGroup as fasObjectGroup,
    faPlayCircle as fasPlayCircle,
    faShoePrints as fasShoePrints,
    faSparkles as fasSparkles,
    faTimes as fasTimes,
    faTrophy,
    faTimesCircle as fasTimesCircle,
    faUserCheck as fasUserCheck,
    faUserClock as fasUserClock,
    faUserHeadset as fasUserHeadset,
    faUsers as fasUsers,
    faUsersClass,
    faUserSlash as fasUserSlash,
    faVideo as fasVideo,
    faChevronUp as fasChevronUp,
    faChevronDown as fasChevronDown,
    faEye as fasEye,
    faEyeSlash as fasEyeSlash,
} from '@fortawesome/pro-solid-svg-icons'
// Brand icons
import {
    faDiscord as fabDiscord,
    faFacebook as fabFacebook,
    faGoogle as fabGoogle,
    faInstagram as fabInstagram,
    faIntercom as fabIntercom,
    faLinkedin as fabLinkedin,
    faYoutube as fabYoutube,
} from '@fortawesome/free-brands-svg-icons'
// Duotone icons
import { faBuilding as fadBuilding, faCircle as fadCircle } from '@fortawesome/pro-duotone-svg-icons'
// Regular icons
import {
    faArrowLeft as farArrowLeft,
    faArrowRightFromBracket as farArrowRightFromBracket,
    faBadgeCheck as farBadgeCheck,
    faBallotCheck as farBallotCheck,
    faBars as farBars,
    faBolt as farBolt,
    faBug as farBug,
    faCalendar as farCalendar,
    faCalendarClock as farCalendarClock,
    faCartShopping as farCartShopping,
    faCircleNodes as farCircleNodes,
    faCirclePlay as farCirclePlay,
    faCoinBlank as farCoinBlank,
    faFile as farFile,
    faFilm as farFilm,
    faGaugeHigh as farGaugeHigh,
    faGaugeLow as farGaugeLow,
    faInfoCircle as farInfoCircle,
    faLanguage as farLanguage,
    faMagnifyingGlass as farMagnifyingGlass,
    faMapPin as farMapPin,
    faMegaphone as farMegaphone,
    faMessageQuestion as farMessageQuestion,
    faPenToSquare as farPenToSquare,
    faPeopleGroup as farPeopleGroup,
    faRankingStar as farRankingStar,
    faShoePrints as farShoePrints,
    faSpinner as farSpinner,
    faTimes as farTimes,
} from '@fortawesome/pro-regular-svg-icons'

// Thin icons
import { faCheckCircle as fatCheckCircle } from '@fortawesome/pro-thin-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'

library.add(
    faArrowRight,
    fabDiscord,
    faBell,
    fabFacebook,
    fabGoogle,
    fabInstagram,
    fabIntercom,
    fabLinkedin,
    fabYoutube,
    faCheckCircle,
    faCheckSquare,
    faCircleNotch,
    faCircleQuestion,
    faClock,
    faCoffee,
    fadBuilding,
    fadCircle,
    faHandPaper,
    falArrowLeft,
    falArrowRight,
    falArrowRight,
    falArrowRight,
    falArrowRightArrowLeft,
    falArrowRightFromBracket,
    falBars,
    falBell,
    falBolt,
    falBrowser,
    falBullseyeArrow,
    falCalendar,
    falCamera,
    falCameraMovie,
    falCartShopping,
    falChartNetwork,
    falCheck,
    falChevronLeft,
    falChevronLeft,
    falChevronLeft,
    falChevronRight,
    falChevronRight,
    falChevronRight,
    falCircle,
    falCircleArrowDown,
    falCircleCheck,
    falCircleInfo,
    falCircleLocationArrow,
    falCircleNodes,
    falCirclePlay,
    falCircleQuestion,
    falClipboard,
    falClock,
    falCode,
    falCode,
    falCode,
    falCoinBlank,
    falComments,
    falCopy,
    falCube,
    falDatabase,
    falDownload,
    falDownload,
    falDownload,
    falDrawCircle,
    falEgg,
    falEgg,
    falFileCertificate,
    falFileCertificate,
    falFileCertificate,
    falFilePdf,
    falFolder,
    falFutbol,
    falGamepadModern,
    falGauge,
    falGaugeMax,
    falGraduationCap,
    falGraduationCap,
    falGraduationCap,
    falGrid2,
    falHeart,
    falHome,
    falInfoCircle,
    faLinkSimple,
    faListUl,
    falLinkSimple,
    falLinkSimple,
    falLinkSimple,
    falList,
    falMailbox,
    falMedal,
    falMusic,
    falNote,
    falPlane,
    falPlayCircle,
    falScreenUsers,
    falScrewdriverWrench,
    falSearch,
    falShoePrints,
    falShoppingBag,
    falShoppingCart,
    falStreetView,
    falThumbsDown,
    falTimes,
    falTreePalm,
    falTrophy,
    falTvRetro,
    falUser,
    falUserCheck,
    falUserClock,
    falUserGroup,
    falUserHeadset,
    falUsers,
    falUserSlash,
    falVideo,
    faMailbox,
    faMedal,
    farArrowLeft,
    farArrowRightFromBracket,
    farBadgeCheck,
    farBallotCheck,
    farBars,
    farBolt,
    farBug,
    farCalendar,
    farCalendar,
    farCalendarClock,
    farCartShopping,
    farCircleNodes,
    farCirclePlay,
    farCoinBlank,
    farFile,
    farFilm,
    farGaugeHigh,
    farGaugeLow,
    farInfoCircle,
    farLanguage,
    farMagnifyingGlass,
    farMegaphone,
    farMapPin,
    farMessageQuestion,
    farPenToSquare,
    farPeopleGroup,
    farRankingStar,
    farShoePrints,
    farSpinner,
    farTimes,
    fasArrowLeft,
    fasArrowRight,
    fasArrowRightFromBracket,
    fasBolt,
    fasBug,
    fasCalendarCheck,
    fasCalendarCirclePlus,
    fasCalendarPlus,
    fasCamera,
    fasChartNetwork,
    fasCheck,
    fasCheckCircle,
    fasChevronLeft,
    fasChevronRight,
    fasCircle,
    fasCircleArrowRight,
    fasCircleCheck,
    fasCircleExclamation,
    fasCircleExclamation,
    fasCircleNodes,
    fasCircleQuestion,
    fasCircleVideo,
    fasCode,
    fasCoinVertical,
    fasComments,
    fasDownload,
    fasDrawCircle,
    fasEgg,
    fasFileCertificate,
    fasFolder,
    fasFolderOpen,
    fasGraduationCap,
    fasHeart,
    fasHome,
    fasInfoCircle,
    fasLanguage,
    fasLinkSimple,
    fasList,
    fasLock,
    fasLockOpen,
    fasMapPin,
    fasMessageQuestion,
    fasObjectGroup,
    faSpinner,
    fasSparkles,
    fasPlayCircle,
    fasShoePrints,
    fasTimes,
    fasUserCheck,
    fasUserClock,
    fasUserHeadset,
    fasUsers,
    fasUserSlash,
    fasVideo,
    fatCheckCircle,
    fatCircleCheck,
    faTimesCircle,
    faTrophy,
    faUserCircle,
    faUsersClass,
    farPenToSquare,
    farSpinner,
    farBars,
    fasCoinVertical,
    fasShoePrints,
    fasCircleVideo,
    fasCalendarPlus,
    fasLock,
    fasList,
    fasCalendarCirclePlus,
    falCircleNodes,
    fasCircleNodes,
    farCircleNodes,
    fadBuilding,
    fasChevronUp,
    fasChevronDown,
    fasEye,
    fasEyeSlash,
    fasTimesCircle
)
