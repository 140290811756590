import { feedbackIntegration, setUser, type User as SentryUserData } from '@sentry/react'

declare global {
    interface Window {
        Sentry?: ReturnType<typeof import('@sentry/react').init>
    }
}

export const initSentry = () => {
    const dsn = process.env.REACT_APP_SENTRY_DSN
    if (!isSentryEnable)
        return console.warn('Sentry is disabled because REACT_APP_SENTRY_DSN is not set')

    import('@sentry/react').then(Sentry => {
        window.Sentry = Sentry.init({
            dsn,
            tracesSampleRate: 0.25,
            tracePropagationTargets: ['localhost', /^https:\/\/egg\.live/],
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,

            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration(),
                Sentry.feedbackIntegration({
                    name: 'feedbackIntegration',
                    autoInject: false,
                    colorScheme: 'system',
                    // Custom options
                    showBranding: false,
                    /// Translation
                    triggerLabel: 'Enviar error',
                    triggerAriaLabel: 'Enviar error',
                    buttonLabel: 'Feedback',
                    submitButtonLabel: 'Enviar Feedback',
                    formTitle: 'Enviar Feedback',
                    cancelButtonLabel: 'Cancelar',
                    addScreenshotButtonLabel: 'Adjuntar captura de pantalla',
                    nameLabel: 'Nombre',
                    namePlaceholder: 'Tu nombre',
                    emailLabel: 'Correo electrónico',
                    emailPlaceholder: 'Tu correo electrónico',
                    messageLabel: 'Descripción del error',
                    messagePlaceholder: 'Describe el error que encontraste',
                    successMessageText: '¡Gracias por tu feedback!',
                }),
            ],
        })
    })
}

export const sendUserFeedback = async (userData?: SentryUserData) => {
    if (!window.Sentry)
        return console.warn('Sentry is disabled because REACT_APP_SENTRY_DSN is not set')

    if (userData) setUser(userData)

    const feedBack =
        window.Sentry.getIntegrationByName<ReturnType<typeof feedbackIntegration>>('Feedback')

    if (!feedBack) return console.warn('Feedback integration not found')
    feedBack.createForm().then(form => {
        form.appendToDom()
        form.open()
    })
}

export const isSentryEnable = process.env.REACT_APP_SENTRY_DSN?.startsWith('https://') ?? false

export type { SentryUserData }
