import { ProgressHTMLAttributes } from 'react'
import { useUserCourses } from '../../hooks/queries/useUserCourses'
import { useCourseStudyPlan } from '../../hooks/useCourseStudyPlan'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export const CourseProgression = ({ courseId, props }: { courseId?: string; props?: ProgressHTMLAttributes<HTMLProgressElement> }) => {
    const { t } = useTranslation('course')
    const { plan } = useCourseStudyPlan(courseId)
    const { getCourseData } = useUserCourses()

    const currentCourse = getCourseData(courseId)

    const courseStatus = currentCourse?.status

    let totalSteps = 0
    let alreadyStep = 0
    let percent = 0

    if (currentCourse?.asyncCourse) return null

    if (!courseId || !plan) return <Skeleton />

    if (courseStatus === 'finished') percent = 100
    if (courseStatus === 'studying') {
        plan.sessions.forEach(session => {
            totalSteps += session.steps.length
            if (session.steps.some(({ status }) => status !== 'blocked')) alreadyStep += session.steps.length
        })

        if (totalSteps) percent = Math.round((alreadyStep / totalSteps) * 100)
    }
    return (
        <Container className="progress-bar only-desktop">
            <progress id="course-progress" max="100" value={percent} {...props} />
            <span>{t('{{ percent }}% GLOBAL ADVANCE', { percent })}</span>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    margin-top: 1em;
    flex-direction: column;
    gap: 1ch;
    text-transform: uppercase;
`

const Skeleton = ({ props }: { props?: ProgressHTMLAttributes<HTMLProgressElement> }) => {
    const { t } = useTranslation('dashboard')

    return (
        <Container className="progress-bar only-desktop">
            <progress id="course-progress" max="100" value={0} {...props} />
            <span>{t('{{ percent }}% GLOBAL ADVANCE', { percent: 0 })}</span>
        </Container>
    )
}
