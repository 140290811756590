/**
 *  Translation service: https://www.locize.app/
 *  Locize documentation: https://docs.locize.com/integration/instrumenting-your-code
 *  Credentials: https://teampass.eggcooperation.com
 *  */

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-locize-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import DayJS from './helpers/DayJS'
import es from 'dayjs/locale/es'
import en from 'dayjs/locale/en'
import { isProduction } from './interfaces/constants'

const locizeOptions = {
    projectId: `${process.env.REACT_APP_LOCIZE_PROJECT_ID}`,
    apiKey: `${process.env.REACT_APP_LOCIZE_API_KEY}`,
    referenceLng: process.env.REACT_APP_LOCIZE_REFLNG ?? 'es',
    version: process.env.REACT_APP_LOCIZE_VERSION ?? 'latest',
}

i18n.use(initReactI18next)
    .use(Backend)
    .use(LanguageDetector)
    .init(
        {
            debug: false,
            ns: [],
            load: 'languageOnly',
            fallbackLng: 'en',
            backend: locizeOptions,
            interpolation: { escapeValue: false },
            react: {
                useSuspense: true,
            },
            cache: ['localStorage'],
            detection: {
                order: [
                    'querystring',
                    'cookie',
                    'localStorage',
                    'sessionStorage',
                    'navigator',
                    'htmlTag',
                    'path',
                    'subdomain',
                ],
                caches: ['localStorage', 'cookie'],
                cookieOptions: { path: '/', sameSite: 'strict' },
            },
        },
        err => {
            if (err) return console.log('something went wrong loading', err)
        }
    )

i18n.on('initialized', async () => {
    const data: AvailableLanguages = await i18n.services.backendConnector.backend.getLanguages()
    const supportedLngs = Object.keys(data)
    const referenceLanguage = Object.entries(data).find(
        ([, { isReferenceLanguage }]) => isReferenceLanguage
    )?.[0]
    i18n.options.supportedLngs = supportedLngs
    i18n.options.fallbackLng = referenceLanguage
})

i18n.on('missingKey', (languages, namespace, key, res) =>
    console.error({ languages, namespace, key, res })
)

i18n.on('languageChanged', lng => {
    !isProduction && console.info(`Language changed to ${lng}`)
    const userLanguage = lng.split('-').at(0)
    userLanguage === 'es' ? DayJS.locale(es) : DayJS.locale(en)
})

export default i18n

export const plural = {
    count: 10,
}

export type AvailableLanguages = Record<
    string,
    {
        name: string
        nativeName: string
        isReferenceLanguage: boolean
        translated: {
            latest: number
        }
    }
>
