/**
 * Elfsight manager website: https://dash.elfsight.com/apps/website-translator
 */

import { ElfsightWidget as Widget } from 'react-elfsight-widget'
import { NavLink, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Icon } from './Icon'
import { useUser } from '../hooks/queries/useUser'
const { REACT_APP_TRANSLATE_CONTENT_FOR_ORGANIZATIONS } = process.env

const enableElfsightWidgetsForOrganization = REACT_APP_TRANSLATE_CONTENT_FOR_ORGANIZATIONS?.split(',') ?? []

export const TranslationWidget = () => {
    const { t } = useTranslation('course')
    const { user } = useUser()
    const [searchParams] = useSearchParams()
    const lang = searchParams.get('lang')

    const organizationId = user?.organizationId

    if (!organizationId || !enableElfsightWidgetsForOrganization.includes(organizationId)) return null

    if (lang) return <Widget widgetId={'693bb0f3-20e3-477f-86b4-e40638c95455'} lazy modern />

    return (
        <NavLink to={`?lang=es`}>
            <>
                <Icon icon={['fas', 'language']} size="lg" /> {t('ES')}
            </>
        </NavLink>
    )
}
