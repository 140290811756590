import styled from 'styled-components'
import { ModalType, useUIContext } from '../../context/UIContext'
import { PlanSessionDTO } from '../../infrastructure/api-widget'
import { SVGAttributes } from 'react'

const url = process.env.REACT_APP_TRANSCRIPTION_URL
export const SessionsTranscriptionButton = ({ session }: { session: PlanSessionDTO }) => {
    const { dispatchModal } = useUIContext()

    if (!url || url === 'disabled' || session.status !== 'done') return null

    const handleClick = () =>
        dispatchModal({
            type: ModalType.OPEN_URL,
            payload: {
                url,
                header: <FrameHeader title={session.title} />,
            },
        })

    return (
        <Button
            onClick={e => {
                e.stopPropagation()
                handleClick()
            }}
        >
            <ColorSparklesIcon />
        </Button>
    )
}

const Button = styled.button`
    border: 0;
    padding: 0;
    background: 0;
    color: inherit;
    cursor: pointer;
`

const FrameHeader = ({ title }: { title: string }) => {
    return (
        <header style={{ display: 'flex', gap: '1em' }}>
            <ColorSparklesIcon
                style={{
                    width: 'auto',
                    height: '4em',
                }}
            />
            <div>
                <div
                    style={{
                        margin: '0px',
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        color: 'var(--color-light-egg-4)',
                    }}
                >
                    COACH AI
                </div>
                <h1
                    style={{
                        margin: '0 0 1em 0',
                        color: 'inherit',
                        fontSize: '1.625rem',
                    }}
                >
                    {title}
                </h1>
            </div>
        </header>
    )
}

const ColorSparklesIcon = (props: SVGAttributes<SVGSVGElement>) => (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M14.5508 4.44531L17 3.5L17.9023 1.09375C17.9453 0.878906 18.1602 0.75 18.375 0.75C18.5469 0.75 18.7617 0.878906 18.8047 1.09375L19.75 3.5L22.1562 4.44531C22.3711 4.48828 22.5 4.70312 22.5 4.875C22.5 5.08984 22.3711 5.30469 22.1562 5.34766L19.75 6.25L18.8047 8.69922C18.7617 8.87109 18.5469 9 18.375 9C18.1602 9 17.9453 8.87109 17.9023 8.69922L17 6.25L14.5508 5.34766C14.3789 5.30469 14.25 5.08984 14.25 4.875C14.25 4.70312 14.3789 4.48828 14.5508 4.44531ZM9.30859 3.92969L11.543 8.82812L16.4414 11.0625C16.6992 11.1914 16.8711 11.4492 16.8711 11.707C16.8711 11.9648 16.6992 12.2227 16.4414 12.3086L11.543 14.5859L9.30859 19.4844C9.17969 19.7422 8.92188 19.9141 8.66406 19.9141C8.40625 19.9141 8.14844 19.7422 8.0625 19.4844L5.78516 14.5859L0.886719 12.3516C0.628906 12.2227 0.5 11.9648 0.5 11.707C0.5 11.4492 0.628906 11.1914 0.886719 11.0625L5.78516 8.82812L8.0625 3.92969C8.14844 3.67188 8.40625 3.5 8.66406 3.5C8.92188 3.5 9.17969 3.67188 9.30859 3.92969ZM17 17.25L17.9023 14.8438C17.9453 14.6289 18.1602 14.5 18.375 14.5C18.5469 14.5 18.7617 14.6289 18.8047 14.8438L19.75 17.25L22.1562 18.1953C22.3711 18.2383 22.5 18.4531 22.5 18.625C22.5 18.8398 22.3711 19.0547 22.1562 19.0977L19.75 20L18.8047 22.4492C18.7617 22.6211 18.5469 22.75 18.375 22.75C18.1602 22.75 17.9453 22.6211 17.9023 22.4492L17 20L14.5508 19.0977C14.3789 19.0547 14.25 18.8398 14.25 18.625C14.25 18.4531 14.3789 18.2383 14.5508 18.1953L17 17.25Z"
            fill="url(#paint0_linear_1_429)"
        />
        <defs>
            <linearGradient id="paint0_linear_1_429" x1="14" y1="2.5" x2="5.5" y2="20" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF647C" />
                <stop offset="1" stop-color="#00A3FF" />
            </linearGradient>
        </defs>
    </svg>
)
