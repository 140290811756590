import Axios from 'axios'
import { GetCoursesOfStudentResponse, GetGlobalRankingResponse, GetUserByIdResponse } from '../interfaces/api-ranking'
import { refreshInterceptor } from './apis'

const { REACT_APP_RANKING_API } = process.env

if (!REACT_APP_RANKING_API) console.error('REACT_APP_RANKING_API to be declared on your system environments variables')

const baseURL = `${REACT_APP_RANKING_API}`

export const ApiRanking = Axios.create({
    baseURL,
})

ApiRanking.interceptors.response.use(
    ok => ok,
    error => refreshInterceptor(error, ApiRanking)
)

export const getRankingCourses = () => ApiRanking.get<GetCoursesOfStudentResponse>(`/ranking/courses`).then(({ data }) => data.data)

export const getUserRanking = async ({ course, type }: UserRankingParams) => {
    const courseQuery = course ? `course=${course}` : ''
    const typeQuery = type ? `&type=${type}` : ''
    const { data } = await ApiRanking.get<GetUserByIdResponse>(`/ranking/me?${courseQuery}${typeQuery}`)
    return data.data
}

export const getRanking = async ({ type, course, page, name, myposition }: RankingParams) => {
    const typeQuery = type ? `&type=${type}` : ''
    const courseQuery = course ? `&course=${course}` : ''
    const pageQuery = page ? `&page=${page}` : ''
    const nameQuery = name ? `&name=${name}` : ''
    const myPositionQuery = myposition ? `&myposition=${myposition}` : ''

    const { data } = await ApiRanking.get<GetGlobalRankingResponse>(
        `/ranking/list?${typeQuery}${courseQuery}${pageQuery}${nameQuery}${myPositionQuery}`
    )
    return data.data
}

export type UserRankingParams = {
    course?: string
    type?: string
}

export type RankingParams = {
    course?: string
    type?: string
    page?: number | string
    name?: string
    myposition?: string
}
