import { useQuery } from '@tanstack/react-query'
import { getUserCourses as queryFn } from '../../infrastructure/api-signup'
import { useToken } from '../useToken'
import { extendCourseData } from '../../helpers/courses'

export const useUserCourses = () => {
    const { _id, _classId } = useToken()

    const queryKey = ['User', _id, 'Courses']

    const { data: rawCourses, ...rest } = useQuery({
        queryKey,
        queryFn,
        enabled: !!_id,
    })

    const courses = rawCourses?.map(extendCourseData)

    const getCourseData = (slugOrId?: string) => courses?.find(course => course.courseId === slugOrId)

    const currentCourse = getCourseData(_classId)

    const currentCourseName = currentCourse?.networkName ?? currentCourse?.courseName

    return {
        courses,
        currentCourse,
        currentCourseName,
        getCourseData,
        ...rest,
    }
}

export const useUserCourse = (slugOrId?: string) => {
    const { courses, ...rest } = useUserCourses()
    const course = courses?.find(({ courseId, slug }) => slug === slugOrId || courseId === slugOrId)
    return { course, courseId: course?.courseId, courseSlug: course?.slug, ...rest }
}
