import Axios from 'axios'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { SendGAEvent, GACategory } from '../../helpers/GAEvents'
import { createUser, migrateUser } from '../../pages/register/api-register'
import { useIntercom } from '../useIntercom'
import { getWhoAmI } from '../../infrastructure/api-signup'

export const useCreateUser = () => {
    const { t } = useTranslation('signUp')
    const { executeRecaptcha } = useGoogleReCaptcha()
    const navigate = useNavigate()
    const { clientUpdate, newMessage } = useIntercom()

    const { mutate: create, ...rest } = useMutation({
        retry: false,
        mutationFn: async (data: Parameters<typeof createUser>[0]) => {
            const captcha_token = (await executeRecaptcha?.('createUser')) ?? ''
            return createUser(data, captcha_token)
        },

        onSuccess: () => {
            navigate(`/registro/2`)
        },

        onError: async (err, { email }) => {
            if ((err as any)?.response?.status === 409) {
                const captcha_token = (await executeRecaptcha?.('createUser')) ?? ''
                return await handleExistingUser({ email, captcha_token })
            }

            SendGAEvent(GACategory.ERROR, `An error has occurred while a user is trying to register.`)
            clientUpdate({
                email,
                language_override: 'es',
            })
            newMessage(t('Hello, I need help to register in Egg!'))
            navigate(`/registro/error?email=${email}`)
        },
    })

    const { mutate: migrate, mutateAsync: migrateAsync } = useMutation({
        mutationFn: migrateUser,
        onSuccess: () => navigate(`/registro/2`),
        onError: (err, { email }) => {
            SendGAEvent(GACategory.ERROR, `An error has occurred while a user is trying to migrate.`)

            clientUpdate({
                email,
                language_override: 'es',
            })
            newMessage(t('Hello, I need help to migrate my user info in Egg!'))

            if (Axios.isAxiosError(err)) err.response?.status === 409 && navigate(`/registro/error?email=${email}`)
        },
    })

    const handleExistingUser = async (params: { email: string; captcha_token: string }) => {
        try {
            const { goTo } = await getWhoAmI(params)
            navigate(goTo)
        } catch (_error) {
            navigate('/login')
        }
    }
    return { create, migrate, migrateAsync, ...rest }
}
