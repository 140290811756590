import styled from 'styled-components'
import { defaultTheme } from '../../styles/themes/defaultTheme'
import { ComponentProps, useState } from 'react'
import { useParams } from 'react-router-dom'
import { PlanSessionDTO } from '../../infrastructure/api-widget'
import { Icon } from '../Icon'
import { StpListItem } from './StpListItem'
import { SessionProgressTracker } from './SessionProgressTracker'
import { SessionsTranscriptionButton } from './SessionsTranscriptionButton'

export const SessionStepList = ({ session, isFolderOpen, onTitleClick }: ContentCardProps) => {
    const { stepId } = useParams()

    const [isOpen, setIsOpen] = useState(!!isFolderOpen)

    const { status } = session

    const iconProps: ComponentProps<typeof Icon> = {
        icon: ['fas', 'circle-arrow-right'],
        color: defaultTheme.color.primary,
        size: 'lg',
    }

    if (status === 'blocked') {
        iconProps.icon = ['fas', 'lock']
        iconProps.color = defaultTheme.color.gray
    }

    if (status === 'done') {
        iconProps.icon = ['fas', 'check-circle']
        iconProps.color = defaultTheme.color.greenHarmony
    }

    const hasSteps = !!session.steps?.length

    return (
        <Container className={`${status} ${isOpen ? 'open' : 'close'} border-radius`}>
            <article
                data-id={session.id}
                className={`section-card border-radius ${isOpen ? 'open' : ''} ${status === 'blocked' ? 'disabled' : ''}`}
                onClick={onTitleClick}
            >
                <div>
                    <Icon {...iconProps} />
                </div>

                <div className="section-card-title">
                    <h4 className="truncate">{session.title}</h4>
                </div>

                <div className="section-card-right-item display-flex only-desktop">
                    <SessionProgressTracker session={session} stepId={stepId} />
                    <SessionsTranscriptionButton session={session} />
                    {hasSteps && (
                        <button
                            className="folder"
                            onClick={e => {
                                e.stopPropagation()
                                setIsOpen(prev => !prev)
                            }}
                        >
                            <Icon
                                style={{ fontSize: '22px' }}
                                icon={status === 'blocked' ? ['fal', 'folder'] : isOpen ? ['fas', 'folder-open'] : ['fas', 'folder']}
                            />
                        </button>
                    )}
                </div>
            </article>

            {isOpen && (
                <StepListWrapper id={session.id}>
                    {session?.steps?.map((step, idx) => (
                        <StpListItem step={step} key={idx + step.id} active={step.id === stepId} />
                    ))}
                </StepListWrapper>
            )}
        </Container>
    )
}

export const ContentNavigationSkeleton = () => (
    <Container className="skeleton">
        <article className="section-card border-radius" />
    </Container>
)

export const ContentSkeleton = () => (
    <>
        <ContentNavigationSkeleton />
        <ContentNavigationSkeleton />
        <ContentNavigationSkeleton />
    </>
)

const Container = styled.section`
    background: white;
    color: ${defaultTheme.color.primary};
    border: 2px solid transparent;
    margin-top: 8px;

    &:not(:last-child) {
        margin-bottom: 1em;
    }

    &.inProgress {
        border-color: ${defaultTheme.color.primary};
    }

    & .icon {
        margin-right: 0;
    }
    & > .section-card {
        width: 100%;
        min-height: 70px;
        padding: 1em 2em;
        display: grid;
        grid-template-areas: 'leftItem title items rightItem';
        grid-template-columns: [leftItem] auto [title] 1fr [items] auto [rightItem] auto;
        grid-column-gap: 2em;
        align-items: center;
        & > .section-card-left-item {
            grid-area: leftItem;
        }
        & > .section-card-title {
            grid-area: title;
            display: grid;
            grid-template-columns: 1fr;
            align-items: center;
            gap: 0.8em;
            max-width: fit-content;
            .section-card-subtitle {
                font-size: 1.14em;
                margin: 0;
            }
            & > h4 {
                font-size: 1.14em;
                font-weight: 600;
                margin: 0;
                &:hover {
                    text-decoration: underline;
                }
            }
            & > h4,
            & > .section-card-subtitle {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            @media screen and (max-width: 768px) {
                grid-template-columns: 1fr;
                row-gap: 0;
                font-size: 14px !important;
                & > h4,
                p {
                    font-size: 1em;
                    font-weight: 600;
                    margin: 0;
                }
                .icon {
                    font-size: 14px;
                }
            }
        }
        & > .section-card-items {
            grid-area: items;
            & > .section-card-duration {
                display: flex;
                align-items: center;
                gap: 0.5em;
                & > .icon {
                    font-size: 13px;
                }
                & > p {
                    margin: 0;
                }
            }
        }
        & > .section-card-right-item {
            grid-area: rightItem;
        }

        @media screen and (max-width: 768px) {
            grid-template-areas: 'leftItem title items';
            grid-template-columns: auto 1fr auto;
            column-gap: 1em;
            row-gap: 2em;
            & > .section-card-items {
                & > nav {
                    justify-content: flex-start;
                }
            }
        }
    }
    & > .disabled {
        color: ${defaultTheme.color.gray};
    }
    .section-card:not(.disabled):not(.open):hover {
        background: ${defaultTheme.color.lightRedGray};
        border-radius: 10px;
    }
    .section-card:not(.disabled):hover {
        cursor: pointer;
    }

    &.blocked {
        pointer-events: none;
    }

    & button.folder {
        background: 0;
        border: 0;
        color: inherit;
        cursor: pointer;
    }
`
const StepListWrapper = styled.div`
    padding: 0 2em 0em 2.3em;
`

type ContentCardProps = {
    session: PlanSessionDTO
    isFolderOpen?: boolean
    setIsOpen?: (sectionId: string) => void
    onTitleClick?: () => void
}
