import { isTokenExpired } from '../../../../helpers/validators'

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ErrorMessage } from '../../../../components/FormElements/Input'
import { PasswordConfirmation } from '../../../../components/FormElements/PasswordConfirmation'
import { changePassword } from '../../../../infrastructure/api-signup'
import { PostCreateUser } from '../../../register/api-register'

export const ResetWithToken = ({ token }: Props) => {
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const { t } = useTranslation('signIn')
    const [changes, setChanges] = useState<Partial<PostCreateUser>>({})
    const { password = '' } = changes
    const [validPasswords, setValidPasswords] = useState(false)
    const [error, setError] = useState<string>()

    const { mutate: submitPasswordReset } = useMutation({
        mutationFn: changePassword,
        onSuccess: () => {
            queryClient.refetchQueries({ queryKey: ['User'] })
            navigate('/login')
        },
        onError: error =>
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            setError(t((error as any)?.response?.data?.message || 'Unable to reset password')),
    })

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        submitPasswordReset({ token, password })
    }

    if (isTokenExpired(token))
        return (
            <section className="center" style={{ paddingTop: '50%' }}>
                <h3>{t('Oops! Your link has expired.')}</h3>
                <p className="light">
                    {t(
                        'It seems that the link you were trying to use is no longer available. This usually happens when more than 48 hours have passed since its issuance or if it has already been used.'
                    )}
                </p>
                <button className="text" children={t('Back', { ns: 'signUp' })} onClick={() => navigate('/login')} />
            </section>
        )

    return (
        <>
            <form onSubmit={onSubmit}>
                <section>
                    <h1 className="title">{t('Set New Password')}</h1>
                    <br />
                    <PasswordConfirmation
                        {...{
                            password: password ?? '',
                            changes,
                            setChanges,
                            setValidPasswords,
                        }}
                    />
                </section>

                <br />

                <div className="buttons column">
                    <button type="submit" className="primary" children={t('New Password')} disabled={!validPasswords} />

                    <button className="outlined" children={t(`Cancel`)} onClick={() => navigate('/login')} />
                </div>
            </form>
            {error && <ErrorMessage className="t-center">{error}</ErrorMessage>}
        </>
    )
}

interface Props {
    token: string
}
