import { useTranslation } from 'react-i18next'
import { SelectProps, Select } from './Select'
import { useProvinces } from '../../hooks/queries/useProvinces'

export const Province = (props: SelectProps & { countryId?: number }) => {
    const { countryId, ...rest } = props
    const { t } = useTranslation('signUp')
    const { provinces, status } = useProvinces(countryId)
    return (
        <Select
            {...{
                label: t('Province'),
                id: 'provinceId',
                name: 'provinceId',
                autoComplete: 'address-level2',
                disabled: !provinces?.length,
                ...rest,
            }}
        >
            <option value="" disabled>
                {status === 'pending' ? t('Loading...') : t('Select you province')}
            </option>
            {provinces?.map(({ id, name }) => (
                <option key={id} value={id} children={name} />
            ))}
        </Select>
    )
}
