import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { defaultTheme } from '../../../styles/themes/defaultTheme'
import { useTranslation, Trans } from 'react-i18next'
import { useIntercom } from '../../../hooks/useIntercom'

export const RegisterError = () => {
    const { t } = useTranslation('signUp')
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const email = searchParams.get('email')
    const { clientUpdate, newMessage } = useIntercom()

    const openIntercom = () => {
        clientUpdate({
            email: email ?? undefined,
            language_override: 'es',
        })
        newMessage(t('Hello, I need help to register in Egg!'))
    }

    return (
        <StyledRegisterError>
            <h1>{t("We couldn't create your account")}</h1>
            <p>
                {t(
                    'An error occurred while attempting to create your account with the email address'
                )}
                {email && <strong>{` ${email}`}</strong>}.
            </p>
            <p>{t('Please double-check that there are no errors in the information provided.')}</p>
            <p>
                <Trans
                    i18nKey="If you already have an account, please try <0>log in</0> instead of creating a new one."
                    t={t}
                    components={[
                        <Link
                            key="login"
                            to="/login"
                            style={{ textTransform: 'lowercase' }}
                            children={t('Log in')}
                        />,
                    ]}
                />
            </p>
            <p>
                <Trans
                    i18nKey="If you believe this is an error, please <0>contact our technical support</0> for assistance."
                    t={t}
                    components={[<Link to="" onClick={openIntercom} key="intercomOpen" />]}
                />
            </p>

            <button
                className="outlined"
                onClick={() => navigate(-1)}
                children={t('Back')}
                style={{ background: 0 }}
            />
        </StyledRegisterError>
    )
}

const StyledRegisterError = styled.div`
    text-align: center;
    p {
        font-size: 15px;
        color: ${defaultTheme.color.blueSoft};
    }
`
